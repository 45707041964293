export default[
    'Wood',
    'Fire',
    'Earth',
    'Water',
    'Metal'
];

export let ElementType = {
    'Wood': 0,
    'Fire': 1,
    'Earth': 2,
    'Water': 3,
    'Metal': 4
};