export default {
    saveObject(key, valueAsObject){
        localStorage[key] = JSON.stringify(valueAsObject);
    },
    getObject(key){
        let rawJson = localStorage[key];
        if (rawJson){
            return JSON.parse(rawJson);
        }
        return undefined;
    },
    saveValue(key, value){
        localStorage[key] = value;
    },
    getValue(key){
        return localStorage[key];
    }
}