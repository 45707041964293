var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":6}}),_c('v-col',{attrs:{"cols":3}},[_c('DebugButton',{attrs:{"propDataSource":_vm.skillList,"old-icon-list":_vm.oldIconList,"unlockSupportSetting":_vm.pUnlockSupportSetting}})],1)],1)],1),_c('v-main',[_c('v-row',[_c('v-col',{attrs:{"cols":7}},[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.skillList,"item-key":"index","selectable-key":"index","show-group-by":"","group-by":"support","hide-default-footer":"","options":{itemsPerPage:200},"search":_vm.search,"single-select":true,"item-class":_vm.rowClass},on:{"click:row":_vm.doClickOnRow},scopedSlots:_vm._u([{key:"item.m_iIconId",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":_vm.loadOldIcon(item.m_iIconId),"aspect-ratio":"1","max-width":"50","max-height":"50"}})]}},{key:"item.m_sName2",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.doDisplaySkillForm(item)}}},[_vm._v(" "+_vm._s(item.skillNameEn)+" "+_vm._s(item.skillNameFr)+" "+_vm._s(item.skillNameKh)+" ")])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":_vm.tableRenderNewIcon(item),"aspect-ratio":"1","max-width":"50","max-height":"50"}})],1)]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.renderCompletion(item)),expression:"renderCompletion(item)"}],attrs:{"color":"green"}},[_vm._v(" mdi-thumb-up ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('skillForm',{key:_vm.selectedSkill.index,attrs:{"skillData":_vm.selectedSkill,"modal":_vm.showForm,"total":_vm.skillList.length},on:{"closeModal":function($event){return _vm.doCloseForm()},"updateSkills":_vm.onUpdateSkills,"doSelectNextRow":_vm.onSelectNextRow,"doSelectPrevRow":_vm.onSelectPrevRow}})],1)],1),_c('v-col',{attrs:{"cols":5}},[_c('div',{staticClass:"sticky-top"},[_c('iconGridSelection',{attrs:{"skillData":_vm.selectedSkill,"icons":_vm.iconList,"color":_vm.supportColor,"modal":_vm.showModal},on:{"closeModal":function($event){return _vm.doCloseSelection()},"selectIcon":_vm.onUpdatedNewIcon,"changeColor":_vm.changeColor}}),_c('v-alert',{attrs:{"color":"cyan","border":"left","elevation":"2","colored-border":"","icon":"mdi-gamepad"}},[_vm._v(" You completed the validation of "),_c('strong',[_vm._v(_vm._s(_vm.renderRemainingSkills()))]),_vm._v(" skills. ")])],1)])],1)],1),_c('v-footer',{attrs:{"app":""}},[_c('v-row',[_c('v-col',{attrs:{"align":"right"}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-exit-to-app ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }