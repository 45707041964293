let SMPNum = require("../../SMPNum");

class SMPHeroSkillGamePlay {

    constructor(mathGamePlay) {
        this.mathGamePlay = mathGamePlay;

        /* test validate result with game play
        console.log('cost unlock hero skill id 0: '+this.GetNextCost(0, 0, 1).ToReadableAlphabetV2());
        console.log('cost update hero skill id 0 lv1->2: '+this.GetNextCost(0, 1, 1).ToReadableAlphabetV2());
        console.log('cost update hero skill id 0 lv5->15: '+this.GetNextCost(0, 5, 10).ToReadableAlphabetV2());

        console.log('cost unlock hero skill id 6: '+this.GetNextCost(6, 0, 1).ToReadableAlphabetV2());
        console.log('cost update hero skill id 6 lv1->2: '+this.GetNextCost(6, 1, 1).ToReadableAlphabetV2());
        console.log('cost update hero skill id 6 lv5->15: '+this.GetNextCost(6, 5, 10).ToReadableAlphabetV2());*/
    }

    ConfigParamDependingOnSkillId(skillId) {
        let REQlvl = 50;
        let Skspace = 160;
        switch (skillId) {
            case 0:
                REQlvl = 50;
                Skspace = 160;
                break;

            case 1:
                REQlvl = 100;
                Skspace = 100;
                break;

            case 2:
                REQlvl = 200;
                Skspace = 140;
                break;

            case 3:
                REQlvl = 300;
                Skspace = 110;
                break;

            case 4:
                REQlvl = 400;
                Skspace = 130;
                break;

            case 5:
                REQlvl = 500;
                Skspace = 130;
                break;

            case 6:
                REQlvl = 600;
                Skspace = 130;
                break;
        }

        return {
            REQlvl: REQlvl,
            Skspace: Skspace
        };
    }

    GetNextCost(skillId, currentLv, lvAdd = 1) {
        let nextCost;
        let param = this.ConfigParamDependingOnSkillId(skillId);
        let REQlvl = param.REQlvl;
        let Skspace = param.Skspace;
        let Csk = currentLv;

        let smpNum1 = SMPNum.fromNum(75);//=> 3 * 25 = 75
        if (lvAdd === 1) {
            //nextCost = new SMPNum(1.075).Pow(REQlvl + Csk * Skspace) * 3 * 25;
            nextCost = SMPNum.multSmpNum(
                SMPNum.pow(SMPNum.fromNum(1.075), REQlvl + Csk * Skspace),
                smpNum1
            );
        } else {
            //nextCost = (3 * 25 * (new SMPNum(1.075).Pow(REQlvl)) * (new SMPNum(1.075).Pow(skillData.m_iLv * Skspace) * (new SMPNum(1.075).Pow(lvAdd * Skspace) - 1))) / (new SMPNum(1.075).Pow(Skspace) - 1);
            let smpNum2 = SMPNum.pow(SMPNum.fromNum(1.075), REQlvl);
            let smpNum3 = SMPNum.pow(SMPNum.fromNum(1.075), Csk * Skspace);
            let smpNum4 = SMPNum.minus(
                SMPNum.pow(SMPNum.fromNum(1.075), (lvAdd * Skspace)),
                SMPNum.fromNum(1)
            );
            let smpNum5 = SMPNum.minus(
                SMPNum.pow(SMPNum.fromNum(1.075), Skspace),
                SMPNum.fromNum(1)
            );

            nextCost = SMPNum.divSmpNum(
                SMPNum.multSmpNum(
                    smpNum1,
                    SMPNum.multSmpNum(
                        smpNum2,
                        SMPNum.multSmpNum(
                            smpNum3,
                            smpNum4
                        )
                    )
                ),
                smpNum5
            );
        }
        return nextCost;
    }

    GetMaxActiveSkillLevelOnBaseCost(skillId, currentLv, goldAvailable) {
        let param = this.ConfigParamDependingOnSkillId(skillId);
        let REQlvl = param.REQlvl;
        let Skspace = param.Skspace;
        let Csk = currentLv;

        //let resultInNum = ((gold * (SMPNum.FromNum(1.075).Pow(Skspace) - 1)) / (SMPNum.FromNum(3 * 25) * (SMPNum.FromNum(1.075f).Pow(REQlvl + Csk * Skspace)))) + 1;
        let smpNum1 = SMPNum.minus(
            SMPNum.pow(
                SMPNum.fromNum(1.075)
                , Skspace)
            , SMPNum.fromNum(1));
        let smpNum2 = SMPNum.fromNum(75);
        let smpNum3 = SMPNum.pow(SMPNum.fromNum(1.075), REQlvl + Csk * Skspace);

        let resultInNum = SMPNum.plus(
            SMPNum.divSmpNum(
                SMPNum.multSmpNum(goldAvailable, smpNum1)
                , SMPNum.multSmpNum(smpNum2, smpNum3))
            , SMPNum.fromNum(1)
        );

        //int level = (int)Math.Floor(Math.Log(result, 1.075) / Skspace);
        let levelNum = SMPNum.divSmpNum(
            SMPNum.logBaseSmpNum(resultInNum, 1.075),
            SMPNum.fromNum(Skspace)
        );

        let level = levelNum.ToIntValue();

        return level;
    }

    GetSkillBonus(skillID, currentLevel) {
        let baseSkillBonus = 0;
        switch (skillID)
        {
            case 0:
                baseSkillBonus = 50 * (1 + currentLevel);
                break;

            case 1:
                // number of tap per second
                baseSkillBonus = 3 * currentLevel + 4;
                break;

            case 2:
                baseSkillBonus = 3 * currentLevel + 14;
                break;

            case 3:
                baseSkillBonus = 50 * currentLevel + 100;
                break;

            case 4:
                baseSkillBonus = 30 * currentLevel + 40;
                break;

            case 5:
                baseSkillBonus = 5 * currentLevel + 10;
                break;

            case 6:
                return 50 * currentLevel + 100;

            case 7:
                return 50 * currentLevel + 100;

            case 8:
                return -1;

            case 10:
                return -1;
        }
        //let bonusCapacity = SMPEquipmentDataShared.Instance().GetCapacitySkillIncreasePercentage((ActiveSkillType)skillID);
        //let bonusCapacityDirect = SMPEquipmentDataShared.Instance().GetCapacitySkillIncreaseDirectValue((ActiveSkillType)skillID);
        return baseSkillBonus;// + bonusCapacityDirect + Mathf.RoundToInt(baseSkillBonus * bonusCapacity * 0.01);
    }

    //for passive skill
    GetMaxLevelCanUnlockOnStage(stage, currentLevel) {
        let lvUnlock = (stage / 235) - currentLevel;
        if (lvUnlock < 1) {
            return 0;
        }
        return Math.floor(lvUnlock);
    }
}

module.exports = SMPHeroSkillGamePlay;