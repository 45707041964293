import SMPNum from "../../SMPNum";

class ItemImpactBonusModel {
    constructor() {
        this.dmgImpactBonus = new SMPNum(0);
        this.dpsPercentBonus = 0;
        this.dpsImpactBonus = new SMPNum(0);
        this.goldImpactBonus = new SMPNum(0);

        this.hpByRefillImpact = new SMPNum(0);
        this.hpByIncHpImpact = new SMPNum(0);
        this.hpImpactBonus = new SMPNum(0); //total hp bonus
        this.hpPercentByRefill = 0;
        this.hpPercentByIncHp = 0;
        this.hpPercentBonus = 0; //total hp percent

        this.supportSpeedPercentBonus = 0;

        this.dmgImpactBonusBossOnly = new SMPNum(0);
        this.dmgImpactBonusNormalOnly = new SMPNum(0);

        this.dpsPercentBonusBossOnly = 0;
        this.dpsImpactBonusBossOnly = new SMPNum(0);
        this.dpsPercentBonusNormalOnly = 0;
        this.dpsImpactBonusNormalOnly = new SMPNum(0);
    }
}

export default ItemImpactBonusModel;