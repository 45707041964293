<template>
    <!-- App.vue -->
    <v-app>

        <v-app-bar app>
            <!-- -->
            <v-row>

                <v-col :cols="3">
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details

            ></v-text-field>
                </v-col>
                <v-col :cols="6">
                </v-col>
                <v-col :cols="3">
                    <DebugButton :propDataSource="skillList" :old-icon-list="oldIconList" :unlockSupportSetting="pUnlockSupportSetting"/>
                </v-col>
            </v-row>
        </v-app-bar>


        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- Provides the application the proper gutter -->

            <v-row>
            <v-col :cols="7">
            <v-container >

                <v-data-table

                        :headers="headers"
                        :items="skillList"

                        item-key="index"
                        selectable-key="index"

                        class="elevation-1"
                        show-group-by
                        group-by="support"

                        hide-default-footer
                        :options="{itemsPerPage:200}"
                        :search="search"

                        :single-select="true"

                        v-model="selected"

                        @click:row="doClickOnRow"

                        :item-class= "rowClass"

                >

                    <template v-slot:item.m_iIconId="{item}">
                        <v-img
                                :src="loadOldIcon(item.m_iIconId)"
                                aspect-ratio="1"
                                class="grey lighten-2"
                                max-width="50"
                                max-height="50"
                        />

                    </template>

                    <template v-slot:item.m_sName2="{item}">

                        <span v-on:click="doDisplaySkillForm(item)">
                            {{item.skillNameEn}} {{item.skillNameFr}} {{item.skillNameKh}}
                        </span>



                    </template>


                    <template v-slot:item.icon="{item}">
                        <v-row>


                            <v-img
                                    :src="tableRenderNewIcon(item)"
                                    aspect-ratio="1"
                                    class="grey lighten-2"
                                    max-width="50"
                                    max-height="50"
                            />

                        </v-row>
                    </template>


                    <template v-slot:item.completed="{item}">
<!--                        <span>-->
<!--                            {{renderCompletion(item)}}-->
<!--                        </span>-->

                        <v-icon
                                v-show="renderCompletion(item)"
                                color=green>
                                mdi-thumb-up
                        </v-icon>
                    </template>


                </v-data-table>




                <skillForm  :key="selectedSkill.index"
                            :skillData="selectedSkill"
                            :modal="showForm"
                            :total="skillList.length"
                            @closeModal="doCloseForm()"
                            @updateSkills="onUpdateSkills"
                            @doSelectNextRow="onSelectNextRow"
                            @doSelectPrevRow="onSelectPrevRow"/>


            </v-container>

            </v-col>
            <v-col :cols="5"  >


                <div class="sticky-top">


                        <iconGridSelection   :skillData="selectedSkill" :icons="iconList" :color="supportColor"
                                         :modal="showModal" @closeModal="doCloseSelection()" @selectIcon="onUpdatedNewIcon"
                                             @changeColor="changeColor"


                        />


                        <v-alert
                                color="cyan"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-gamepad"
                        >
                            You completed the validation of <strong>{{renderRemainingSkills()}}</strong> skills.
                        </v-alert>





                </div>

            </v-col>
            </v-row>




        </v-main>

        <v-footer app>
            <!-- -->

                <v-row>
                    <v-col align="right">
                    <router-link  style="text-decoration: none; color: inherit;"
                                  to='/'>
                    <v-icon
                            color=red>
                        mdi-exit-to-app
                    </v-icon>
                    </router-link>
                    </v-col>
                </v-row>


        </v-footer>



    </v-app>



</template>

<script>


    //import axios from 'axios'

    import DebugButton from './components/DebugButton.vue'

    import iconGridSelection from './components/IconGridSelection.vue'

    import skillForm from './components/ModalSkillForm.vue'

    import newIcons from './skill-icons-load.js'

    import supportLoad from './support-load.js'

    import iconLoader from './IconLoader.js'

    import skillList from './skill-list.js'


    let tableListData = skillList.data();
    export default {

        data () {
            tableListData.showModal = false;
            tableListData.showForm = false;

            //tableListData.myImage = require('./assets/oldicons/Support-skill-212.png')
            tableListData.selectedSkill = {};
            tableListData.iconList = [];
            tableListData.supportColor = 'green';
            tableListData.skillList = [];
            tableListData.selected = [];
            tableListData.oldIconList = [];
            return tableListData
        },
        components: {

            'DebugButton': DebugButton,
            'iconGridSelection': iconGridSelection,
            'skillForm' : skillForm,

        },

        created: async function () {
            //console.log('SKILL EDITOR - APP VUE CREATED');
            //console.log('SKILL EDITOR : log of pSkillList')
            //console.log(this.pSkillList);

            let oldIconList =  await supportLoad.loadOldIcons();
            this.oldIconList = oldIconList;
            //console.log('OLD ICONS');
            //console.log(oldIconList);

            //loading the local data for the iconList or the one saved already.
            let newIconsList = await newIcons.initIconList();
            this.iconList = newIconsList;

            //loading the data related to the skills from local or from online
            //this.skillList = await supportLoad.loadSkillData();

            this.skillList = [];
            this.pSkillList.forEach(e => {
                this.skillList.push(Object.assign({},e));
            });

            //console.log('did finished created inside App.vue');
            //console.log(this.pSkillList);
            //console.log(this.skillList);
            ////console.log(this.list);


        },

        props:["pSkillList", "pUnlockSupportSetting"],

        methods: {



            changeColor(changeColor) {
                this.supportColor = changeColor;
            },

            async onUpdatedNewIcon(eventData) {

                eventData.skillData.newIcon = eventData.iconName;
                //this.skillList[eventData.skillData.index].newIcon = eventData.iconName;

                let colorList = ['orange','grey','red','blue','green','purple'];

                colorList.forEach(color => {
                    this.iconList[color].forEach(icon => {

                        icon.usedBy = icon.usedBy.filter(e => {
                            return e !== this.selectedSkill.index;
                        })} );
                });

                this.iconList[eventData.iconColor][eventData.iconIndex].usedBy.push(this.selectedSkill.index);


                this.selected = [];
                this.selected.push(this.selectedSkill);

                if (eventData.autoMode) {
                    //need to move automatically to the next onwe
                    //this.$emit('selectIcon',this.selectedSkill.index);
                    let nextIndex = this.selectedSkill.index;
                    if (nextIndex === this.skillList.length-1) {
                        nextIndex = 0;
                    }
                    this.onSelectNextRow(nextIndex);

                }

                //await axios.post("http://139.59.234.91/skills",{list:this.skillList});
                //await axios.post("http://139.59.234.91/icon-skills",{list:this.iconList});

            },

            async onUpdateSkills(eventData) {
                //console.log('ON UPDATE SKILLS' + eventData);
                this.skillList[eventData.index].skillNameKh = eventData.skillNameKh;
                this.skillList[eventData.index].skillNameFr = eventData.skillNameFr;
                this.skillList[eventData.index].skillNameEn = eventData.skillNameEn;
                if (! eventData.dontClose) {
                    this.showForm = false;
                }

                //await axios.post("http://139.59.234.91/skills",{list:this.skillList});
                //await axios.post("http://139.59.234.91/icon-skills",{list:this.iconList});

                this.$store.commit(this.$store.state.commitActions.PUSH_SKILL_SUPPORTS, this.skillList);
            },

            onSelectNextRow(index) {

                if (index<this.skillList.length-1   ) {
                    this.selectedSkill = this.skillList[index+1];
                    this.supportColor = this.selectedSkill.supportData.color;
                    this.selected = [];
                    this.selected.push(this.selectedSkill);
                    this.$nextTick(() => this.$vuetify.goTo('.v-data-table__selected'))
                }
            },

            onSelectPrevRow(index) {
                if (index > 0 ) {
                    this.selectedSkill = this.skillList[index-1];
                    this.supportColor = this.selectedSkill.supportData.color;
                    this.selected = [];
                    this.selected.push(this.selectedSkill);
                    this.$nextTick(() => this.$vuetify.goTo('.v-data-table__selected'))

                }
            },


            tableRenderNewIcon: function(skillItem) {
                let fileName = skillItem.newIcon;//this.skillList[skillItem.index].newIcon;
                if (! fileName) {
                    fileName = 'Support-skill-212.png';
                    return require('./assets/oldicons/' + fileName);
                }else {
                    return require('./assets/skills/' + fileName);
                }



            },

            doClickOnRow : function(item,slot) {
                //console.log('DO CLICK ON ROW');
                //console.log(item);
                console.log(slot);
                this.selectedSkill = item;
                this.selected = [];
                this.selected.push(this.selectedSkill);

                ////console.log(this.showModal);
                this.showModal = true;
                ////console.log(this.showModal);
                //this.selectedSkill = item;
                //console.log(this.selectedSkill);

                this.supportColor = this.selectedSkill.supportData.color;
            },

            doCloseForm: function() {
                this.showForm = false;
            },

            doCloseSelection: function () {
                this.showModal = false;
                //console.log(this.showModal)
            },

            doDisplaySkillForm: function(item) {
                //console.log('did click do displaySkillForm');
                this.showForm = true;
                this.selectedSkill = item;
                this.supportColor = this.selectedSkill.supportData.color;
            },

            doDisplaySelection: function (item) {



                //console.log('did click');
                ////console.log(this.skillList.indexOf(item)) // this line seems to work. interesting.
                ////console.log(item.index);

                //this.skillList[item.index]

                //console.log(this.showModal);
                this.showModal = true;
                //console.log(this.showModal);
                this.selectedSkill = item;
                //console.log(this.selectedSkill);


                this.supportColor = this.selectedSkill.supportData.color;
            },

            loadOldIcon: iconLoader.getOldIconFromFileId,

            increaseCalorie: function (event) {
                this.desserts[event].protein++;
            },

            renderRemainingSkills : function() {

                let itemCompleted =  this.skillList.filter(e => {return this.renderCompletion(e)});
                return `${itemCompleted.length} / ${this.skillList.length}`

            },

            renderCompletion : function(item) {

                if (item.skillNameFr !== 'FR' && item.skillNameEn !== 'EN'
                    && item.skillNameKh !== 'KH'
                    && item.skillNameKh !== 'KH'
                    && item.newIcon
                ) {
                    item.completed = true;
                    return true;
                }else {
                    return false;
                }

            },

            rowClass(item) {
                if (item === this.selectedSkill){
                    return "light-green";
                }
            }

        }
    }


</script>

<style>
    .sticky-top {
        position: sticky;
        top: 90px;
    }

    .style1 {
        background-color: rgb(114,114,67)

    }

</style>

