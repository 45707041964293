class MultipleSessionDataStore {

    constructor(){
        this.heroStore = {};
        this.supportsStore = {};
        this.petStore = {};
        this.bossStore = {};
    }

    setCurrentHeroStore(heroData, heroLevel){
        this.heroStore.heroData = heroData;
        this.heroStore.heroLevel = heroLevel;
    }

    setCurrentSupportSelect(supportersData){
        this.supportersData = supportersData;
    }

    setCurrentPetSelect(petData){
        this.petData = petData;
    }

    setCurrentBossStore(bossData){
        this.bossStore.bossData = bossData;
    }
}

module.exports = MultipleSessionDataStore;