import Vue from 'vue'


//import Vuetify from 'vuetify/lib';

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'

import App from './App.vue'


// import { Chart, registerables } from 'chart.js';
// Chart.register(...registerables);

//import Home from './Home.vue'

const Home  = () => import ('./Home.vue');
const AuthHome = () => import('./auth/AuthHome.vue');
const Navig = () => import('./Nav.vue');

const Balance = () => import('./Balance.vue');

const Kpi = () => import ('./screens/Kpi.vue');

const KpiEditor = () => import ('./kpi-editor/screen/KpiEditor');
const KpiEditorMVC = () => import ('./kpi-editor/screen/KpiEditorMVC');

const Quest = () => import('./Quest.vue');

const Inventory = () => import('./item-editor/screen/ItemEditor.vue');

const QuestEditor = () => import('./quest-editor/screen/QuestEditor.vue');

const Achievement = () => import('./Achievement.vue');

const AchievementSimulation = () => import('./achievement-simulation/screen/AchievementListView.vue');

const ItemManagerView = () => import('./components/items/ItemManagerList.vue');

const UnityServiceDashboard = () => import('./unity-service-dashboard/screen/UnityServiceDashboard');

import vuetify from '@/plugins/vuetify' // path to vuetify export


import VueRouter from 'vue-router'

import store from './store'

import lodashGet from 'lodash/get'
import axios from "axios";



//import axios from 'axios'
//import PickerSelect from './components/PickerSelect.vue'
axios.defaults.withCredentials = true
Vue.config.productionTip = false;

// var vm = new Vue({
//   data: {
//     // declare message with an empty value
//     message: ''
//   },
//   template: '<div>{{ message }}</div>'
// })
// // set `message` later
// vm.message = 'Hello!'

Vue.config.devtools = process.env.NODE_ENV === 'development';


Vue.use(VueRouter);


const routes = [
    {
        path: "/",
        name: "auth-home",
        component: AuthHome
    },
    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {requiresAuth: true},
    },
    {
        path: "/balance",
        name: "balance",
        component: Balance,
        meta: {requiresAuth: true},
    },
    {
        path: "/kpi",
        name: "kpi",
        component: Kpi,
        meta: {requiresAuth: true},
    },
    {
        path: "/kpi-editor",
        name: "kpi-editor",
        component: KpiEditor,
        meta: {requiresAuth: true},
    },
    {
        path: "/kpi-editor-mvc",
        name: "kpi-editor-mvc",
        component: KpiEditorMVC,
        meta: {requiresAuth: true},
    },
    {
        path: '/skill',
        name: 'skill',
        component: App,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/inventory',
        name: 'inventory',
        component: Inventory,
        meta: {requiresAuth: true},
    },
    {
        path: '/quest-editor',
        name: 'quest-editor',
        component: QuestEditor,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/quest',
        name: 'quest',
        component: Quest,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/achievements',
        name: 'achievements',
        component: Achievement,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/achievement-simulation',
        name: 'achievement-simulation',
        component: AchievementSimulation,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/itemManager',
        name: 'itemManager',
        component: ItemManagerView,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/unityServiceDashboard',
        name: 'unityServiceDashboard',
        component: UnityServiceDashboard,
        props: true,
        meta: {requiresAuth: true},
    },





];



const router = new VueRouter({
    routes,
    mode:'history'
    }
    );

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/')
    } else {
        next()
    }
})

axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;
            return router.push('/')
        }
    }
})


Vue.prototype.$get = lodashGet

 new Vue({
     router:router,
     vuetify: vuetify,
     store:store,
     el:"#app",
     render: h => h(Navig),
   data: {

   }
});
     //.$mount('#app')



//Vue.config.devtools = process.env.NODE_ENV === 'development'

