import axios from 'axios'
import NetworkUtils from "@/NetworkUtils";
import store from "./store";

export default {
    initIconList: async () => {

        let authorize = `Bearer ${store.getters.getToken}`;
        let config = {
            headers: {Authorization: authorize}
        };

        let data = await axios.get(NetworkUtils.HOST + "/icon-skills?_limit=1&_sort=id:DESC", config);
        let list = {};
        if (data.data.length > 0) {
            //console.log('WIL LOAD DATA FROM SERVER');
            list = data.data[0].list;
        } else {

            let colors = ['red', 'blue', 'green', 'grey', 'orange', 'purple'];

            let colorUtil = {
                'red': {colorFolder: '_Red/red_', max: 76},
                'green': {colorFolder: '_Green/green_', max: 145},
                'blue': {colorFolder: '_Blue/blue_', max: 179},
                'grey': {colorFolder: '_Grey/grey_', max: 57},
                'orange': {colorFolder: '_Orange/orange_', max: 135},
                'purple': {colorFolder: '_Purple/violet_', max: 135}
            };

            colors.forEach((color) => {
                let folder = colorUtil[color].colorFolder;
                list[color] = [];
                for (let index = 1; index <= colorUtil[color].max; index++) {
                    let colorPrefix = '00';
                    let path = folder + colorPrefix + index + '.png';

                    if (index >= 10 && index < 100) {
                        colorPrefix = '0';
                        path = folder + colorPrefix + index + '.png';
                    }
                    if (index >= 100) {
                        colorPrefix = '';
                        path = folder + index + '.png';
                    }
                    list[color].push({src: path, usedBy: []});
                }
            })
        }
        return list;
    }
}
