module.exports =  {
    SUPPORT_TAP_DAMAGE : 1,
    SUPPORT_ALL_DAMAGE : 2,
    SUPPORT_SUPPORT_DAMAGE: 3,
    SUPPORT_PASSIVE_GOLD_DROP : 4,
    SUPPORT_PASSIVE_TAP_DAMAGE_TOTAL_DPS: 5,
    SUPPORT_PASSIVE_CAT_BONUS : 6,
    SUPPORT_PASSIVE_CRITICAL_DMG :7,
    SUPPORT_PASSIVE_CRITICAL_CHANCE : 8,
    SUPPORT_PASSIVE_MANA_BONUS: 9,
    SUPPORT_PASSIVE_WAVE_REDUCE: 10,

    SUPPORT_DAMAGE  : "SUPPORT_DAMAGE",
    TAP_DAMAGE : "TAP_DAMAGE",
    ALL_DAMAGE : "ALL_DAMAGE",
    GOLD_DROP: "GOLD_DROP",
    TAP_DAMAGE_TOTAL_DPS :"TAP_DAMAGE_TOTAL_DPS",
    CAT_BONUS : "CAT_BONUS",
    CRITICAL_DMG: "CRITICAL_DMG",
    CRITICAL_CHANCE:"CRITICAL_CHANCE",
    MANA_BONUS:"MANA BONUS",
    WAVE_REDUCE:"WAVE_REDUCE",

    bySkillTypeId : function(skillType) {
        let res = "";
        if (skillType === 1) {
            res = 'SUPPORT_DAMAGE';
        }else if (skillType === 2) {
            res = 'TAP_DAMAGE';
        }else if (skillType === 3) {
            res = 'TAP_DAMAGE_TOTAL_DPS';
        }else if (skillType === 4) {
            res = 'ALL_DAMAGE';
        }else if (skillType === 5) {
            res = 'WAVE_REDUCE';
        }else if (skillType === 6) {
            res = 'CRITICAL_CHANCE';
        }else if (skillType === 7) {
            res = 'CRITICAL_DMG';
        }else if (skillType === 8) {
            res = 'GOLD_DROP';
        }else if (skillType === 9) {
            res = 'CAT_BONUS';
        }else if (skillType === 10) {
            res = 'EVOLVE';
        }else if (skillType === 11) {
            // noinspection SpellCheckingInspection
            res = 'MANA BONUS';
        }
        return res;
    }

    /*
let displaySkillLabel = function (skillType) {
    let res = "";
    if (skillType === 1) {
        res = 'SUPPORT_DAMAGE';
    }else if (skillType === 2) {
        res = 'TAP_DAMAGE';
    }else if (skillType === 3) {
        res = 'TAP_DAMAGE_TOTAL_DPS';
    }else if (skillType === 4) {
        res = 'ALL_DAMAGE';
    }else if (skillType === 5) {
        res = 'WAVE_REDUCE';
    }else if (skillType === 6) {
        res = 'CRITICAL_CHANCE';
    }else if (skillType === 7) {
        res = 'CRITICAL_DMG';
    }else if (skillType === 8) {
        res = 'GOLD_DROP';
    }else if (skillType === 9) {
        res = 'CAT_BONUS';
    }else if (skillType === 10) {
        res = 'EVOLVE';
    }else if (skillType === 11) {
        // noinspection SpellCheckingInspection
        res = 'MANA BONUS';
    }
    return res;
};
*/


};
