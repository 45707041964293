let BonusConstants = require("./../src/constants/BonusConstants");

let stripFileName = function(fileName) {
    let temp = fileName.split('/')[1];
    let test = temp.split('.')[0];
    return test;

};

// noinspection SpellCheckingInspection
export default {

    usedByNewSkill : function (oldIconList,skillList) {
        //console.log('USED BY NEW SKILL');
        //console.log(skillList);
        //console.log(skillList[0]);
        //console.log(oldIconList[0]);
        let listToDelete = [];
        oldIconList.forEach((oldIconName) => {
            let changedIcon  = true;
            skillList.forEach( (skill) => {
                if (skill.m_iIconId === oldIconName) {
                    if (! skill.newIcon) {
                        changedIcon = false;
                    }
                }
            });
            if (changedIcon) {
                listToDelete.push(oldIconName);
            }
        });
        return listToDelete;
    },

    exportListOfIconsUsed : function(skillList) {
        let skillUsed = [];
        skillList.forEach((skill)=>{
             if (skill.newIcon) {
                 if (! skillUsed.includes(skill.newIcon)) {
                     skillUsed.push(skill.newIcon);
                 }
             }
        });
        return skillUsed;
    },

    getSupportColorType(color){
        if(color === "orange"){
            return 4;
        } else if(color === "red"){
            return 0;
        } else if(color === "purple"){
            return 5;
        } else if(color === "blue"){
            return 3;
        } else {
            return -1;
        }
    },

    exportGameSkillStructures: function(skillList, supportUnlockSetting) {
        let list = [];
        let idKeys = [];
        let listGrouped = skillList.reduce(function (r, a) {
            if(r[a.support_id] === undefined){
                r[a.support_id] = [];
                idKeys.push(a.support_id);
            }
            r[a.support_id].push(a);
            return r;
        }, Object.create(null));

        for (let i=0; i<idKeys.length; i++) {
            let supportSkills = listGrouped[idKeys[i]];
            let kpiUnlk = supportUnlockSetting.find(sulck => sulck.m_iID === supportSkills[0].support_id).kpiGameLevelShouldUnlock;
            let supportClone = {
                "m_iID": supportSkills[0].support_id,
                "m_sName": supportSkills[0].supportData.m_sName,
                "m_bHired": false,
                "m_evolved": false,
                "m_bUnlocked": false,
                "m_iFruitType": this.getSupportColorType(supportSkills[0].color),
                "m_fAttackSpeed": supportSkills[0].supportData.m_fAttackSpeed,
                "m_iCurrentLevel": 0,
                "m_sAttackDamagesBase": 1,
                "kpiGameLevelShouldUnlock": kpiUnlk,
                "m_SupportsAbilityList": [],
            };
            supportSkills.forEach((skill) => {
                let skillClone = {
                    "m_iID": skill.skill_id,
                    "m_sName": skill.skillNameEn,
                    "m_bUnlocked": false,
                    "m_skillType": BonusConstants[skill.skillType],
                    "m_skillPercent": skill.m_skillPercent,
                    "m_iCurrentLevel": skill.m_iCurrentLevel
                };
                skillClone.m_iIconId = skill.newIcon;
                if (!skillClone.m_iIconId) {
                    skillClone.m_iIconId = skill.m_iIconId;
                }else {
                    skillClone.m_iIconId = stripFileName(skill.newIcon)
                }
                supportClone.m_SupportsAbilityList.push(skillClone);
            });
            list.push(supportClone);
        }
        return list;

    },


    exportGameLanguageData : function (language,skillList) {

        let methodLine = `private void Set${language}SupportInfo() {`;
        let content = methodLine;
        let idKeys = [];
        let listGrouped = skillList.reduce(function (r, a) {
            if(r[a.support_id] === undefined){
                r[a.support_id] = [];
                idKeys.push(a.support_id);
            }
            r[a.support_id].push(a);
            return r;
        }, Object.create(null));

        for (let i=0; i<idKeys.length; i++) {
            let supportSkills = listGrouped[idKeys[i]];
            content = content + '\n';
            content = content + 'supporters_info['+ `${supportSkills[0].support_id - 1}`  + '].name                 = "' + `${supportSkills[0].supportData.m_sName}` + '";';
            content = content + '\n';
            let index = 0;
            supportSkills.forEach((skill) => {

                let skillName = skill.skillNameEn;
                if (language === 'FR') {
                    skillName = skill.skillNameFr;
                }
                if (language === 'KH') {
                    skillName = skill.skillNameKh;
                }

                content = content + 'supporters_info['+ `${supportSkills[0].support_id - 1}`  + '].skill_names['+ `${index}`  + ']       = "'+ `${skillName}`  + '";';
                content = content + '\n';
                index++;
            });
        }

         content = content + '}';
        return content;


    }
}
