<template>

            <v-card min-height="400px" max-width="600px">


                <v-card-title v-if="false">
                    Modify icon for skill: {{skillData.index}} {{skillData.supportData.m_sName}}
                    <v-spacer></v-spacer>
                    <div v-bind:class="renderSkillClass()">  {{ relevantSkill() }} ({{skillData.skillType}}) </div>
                </v-card-title>

                <v-divider>  </v-divider>

                <v-slide-x-reverse-transition mode="out-in" appear>
                <v-card  v-show="colorSelected" flat max-height="400" class="overflow-y-auto" >

                    <div >

                        <v-row >

                            <v-col
                                    v-for="n in iconToDisplay"
                                    :key="n.iconIndex"

                                    cols="2"

                            >

                                <v-slide-y-transition>

                                <v-card  flat tile class="d-flex"
                                         v-show="n.usedBy.length === 0"
                                >


                                    <v-img
                                            :src="renderIcon(n.iconIndex)"


                                            aspect-ratio="1"
                                            class="grey lighten-2"
                                            v-bind:class="{selected: isSelected(n)}"
                                            v-on:click="clickImg(n.iconIndex)"


                                    >

                                        <v-row
                                                align="end"
                                                class="fill-height"
                                        >

                                            <v-col
                                                    align-self="start"
                                                    class="pa-0"
                                                    cols="12"
                                            >
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-list-item
                                                        color="rgba(0, 0, 0, .4)"
                                                        dark
                                                >
                                                    <v-list-item-content>

                                                        <v-list-item-subtitle>

                                                            <v-avatar color="red" size="36px"
                                                             v-show="totalUsed(n.iconIndex) > 0"
                                                            >
                                                                <span class="white--text headline">{{totalUsed(n.iconIndex)}}</span>
                                                            </v-avatar>

                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                        </v-row>




                                    </v-img>



                                </v-card>

                                </v-slide-y-transition>


                            </v-col>


                        </v-row>


                    </div>


                </v-card>

                </v-slide-x-reverse-transition>

                <v-card  v-show="!colorSelected">
                    <v-card-text>
                        Select a row
                    </v-card-text>
                </v-card>

                <v-divider></v-divider>

                                <v-card-actions v-show="colorSelected">
                                    <v-btn

                                            elevation="2"
                                            icon
                                            rounded
                                            v-bind:color = "filter"
                                            sticky
                                            v-on:click="toggleFilter()"

                                    >
                                        <v-icon>mdi-pin-off</v-icon>

                                    </v-btn>
                                    <v-btn v-bind:outlined="shouldOutline('orange')" color="orange darken-1" text @click="changeColor('orange')">Orange</v-btn>
                                    <v-btn v-bind:outlined="shouldOutline('blue')" color="blue darken-1" text @click="changeColor('blue')">Blue</v-btn>
                                    <v-btn v-bind:outlined="shouldOutline('green')" color="green darken-1" text @click="changeColor('green')">Green</v-btn>
                                    <v-btn v-bind:outlined="shouldOutline('red')" color="red darken-1" text @click="changeColor('red')">Red</v-btn>
                                    <v-btn v-bind:outlined="shouldOutline('grey')" color="grey darken-1" text @click="changeColor('grey')">Grey</v-btn>
                                    <v-btn v-bind:outlined="shouldOutline('purple')" color="purple darken-1" text @click="changeColor('purple')">Purple</v-btn>



                                        <v-icon small
                                                v-on:click="toggleAuto()"
                                                v-bind:color = "renderAutoMode()"
                                        >
                                            mdi-comment-arrow-right-outline
                                        </v-icon>





                                </v-card-actions>

            </v-card>



</template>

<script>

    export default {
        data () {

            return {
                dialog: this.modal,
                overLay: -1,
                iconNumbers: 1,
                iconSelected: -1,
                filter   : "red" ,
                colorSelected: "",
                autoMode: false
            }
        },

        computed : {
            iconToDisplay : function() {
                if (this.colorSelected) {

                    let elements = this.icons[this.color].map((e, index) => {
                        e.iconIndex = index;
                        return e;
                    });

                    if (this.filter === "red") {
                        elements = elements.filter( (e) => this.icons[this.color][e.iconIndex].usedBy.length === 0)
                    }

                    return elements;
                }else {
                    return [];
                }


            }
        },

        updated: function() {
            this.iconNumbers = this.getTotalColors();
        },

        props:['skillData','icons','color','modal','skillIndex','pBehaviour'],

        watch: {
            skillData:{
                handler: 'didUpdateSkillColor'
            },
        },
        methods: {

            toggleAuto : function() {

                if (this.autoMode) {
                    this.autoMode = false;
                }  else {
                    this.autoMode = true;
                }
            },


            toggleFilter : function() {
                if (this.filter === "red") {
                    this.filter = "grey";
                }  else {
                    this.filter = "red";
                }
            },

            changeColor : function(color) {
                this.$emit('changeColor',color);
            },

            iconFilter : function() {
                return "red darken-1"
            },

            clickImg : function(iconIndex) {

                let eventData = {};
                //eventData.iconName = this.icons[this.skillData.color][iconIndex].src;
                eventData.iconName = this.icons[this.color][iconIndex].src;
                eventData.skillData = this.skillData;
                eventData.iconIndex = iconIndex;
                //eventData.iconColor = this.skillData.supportData.color;
                eventData.iconColor = this.color;
                //eventData.iconColor = this.selectedColor;
                eventData.autoMode = this.autoMode;

                this.$emit('selectIcon',eventData);
                this.iconSelected = iconIndex;
            },

            shouldRender : function(n) {
              //console.log('should render ' + n);
              //console.log(this.icons);
                //console.log(this.skillData);

                //if (this.skillData.color) {
                if (this.color) {
                     //if (this.icons[this.skillData.color][n].usedBy.length > 0) {
                    if (this.icons[this.color][n].usedBy.length > 0) {
                            return false;
                     }else {
                         return true;
                     }

                }else {
                    return false;
                }

            },


            closeDialog : function() {
                this.dialog = false;
                //console.log(this.modal);
                this.$emit('closeModal');
            },

            didSelectIcon: function() {
                this.$emit('selectIcon',null)
            },

            didUpdateSkillColor: function(){
                if(this.skillData){
                    this.colorSelected = this.skillData.supportData.color;
                }  else {
                    this.colorSelected = null;
                }
            },

            getTotalColors: function() {

                //return 20;

                //console.log('call getTotalColors');
                ////console.log(this.color);
                ////console.log(this.skillData);

                let result = 0;


                    if (this.icons[this.color]) {
                        if (Array.isArray(this.icons[this.color])) {
                            result = this.icons[this.color].length;
                        }

                    }

                    //console.log(result);

                    if (result > 0) {
                        result = result -1;
                    }
                    return result


              // if (this.skillData.color === 'red') {
              //     return 76;
              // }else {
              //     return 145;
              // }
            },
            renderIcon: function(index) {

                ////console.log('render icon');

                //let image = require('../assets/skills/_Green/green_002.png');
                let image = null;

                if (this.icons && this.icons[this.color] && this.icons[this.color][index]) {
                    //let path = this.icons[this.color][index].toString();
                    let path = this.icons[this.color][index].src.toString();

                    //image = require('@' + '/assets/skills/_Green/green_002.png');
                    //WARNING for any reason if the assets/skills is build outside the files it's not working !?
                    image = require('@' + '/assets/skills/' + path);

                }

                return image;
            },

            isSelected : function(iconIndex) {
                return iconIndex === this.iconSelected;
            },

            totalUsed: function(iconIndex) {
                if (this.color) {
                    return this.icons[this.color][iconIndex].usedBy.length;

                }
            },

            shouldOutline : function(color) {
                return color === this.color;
            },

            relevantSkill :function() {
                if (this.skillData.skillNameEn !== 'EN' ) {
                    return this.skillData.skillNameEn;
                }else {
                    return this.skillData.m_sName;
                }
            },

            renderSkillClass : function() {
                if (this.skillData.skillNameEn !== 'EN' ) {
                    return "green--text"
                }else {
                    return "grey--text"
                }
            },

            renderAutoMode : function() {
                if (this.autoMode) {
                    return "green darken-1"
                }else {
                    return "grey darken-1"
                }
            }



        }
    }
</script>

<style>
    .selected {
        border: solid 12px;
    }

</style>
