
export default {

     getGamePlayRatio(settingRatio) {

          //firstTerm === first value
          //common === ratio

          let gamePlayRatio = {};

          if(settingRatio){

               //DropCoins
               if(settingRatio.dropCoinsFirstTerm){
                    gamePlayRatio["DropCoins FirstTerm"] = {
                         'INTRO' : Number(settingRatio.dropCoinsFirstTerm.dropCoinsGameFirstIntro),
                         'TUNNEL_1' : Number(settingRatio.dropCoinsFirstTerm.dropCoinsFirstTunnel1),
                         'TUNNEL_2' : Number(settingRatio.dropCoinsFirstTerm.dropCoinsFirstTunnel2),
                         'TUNNEL_3' : Number(settingRatio.dropCoinsFirstTerm.dropCoinsFirstTunnel3),
                         'DEFAULT' : Number(settingRatio.dropCoinsFirstTerm.dropCoinsFirstDefault),
                    };
               } else {
                    gamePlayRatio["DropCoins FirstTerm"] = {
                         'INTRO' : Number(settingRatio.seriesFirstTermDropCoins),
                         'TUNNEL_1' : Number(settingRatio.seriesFirstTermDropCoins),
                         'TUNNEL_2' : Number(settingRatio.seriesFirstTermDropCoins),
                         'TUNNEL_3' : Number(settingRatio.seriesFirstTermDropCoins),
                         'DEFAULT' : Number(settingRatio.seriesFirstTermDropCoins),
                    };
               }
               gamePlayRatio["DropCoins Common"] = {
                    'INTRO' : Number.parseFloat(settingRatio.dropCoinsRatio.dropCoinsGameIntro),
                    'TUNNEL_1' : Number.parseFloat(settingRatio.dropCoinsRatio.dropCoinsTunnel1),
                    'TUNNEL_2' : Number.parseFloat(settingRatio.dropCoinsRatio.dropCoinsTunnel2),
                    'TUNNEL_3' : Number.parseFloat(settingRatio.dropCoinsRatio.dropCoinsTunnel3),
                    'DEFAULT' : Number.parseFloat(settingRatio.dropCoinsRatio.dropCoinsDefault),
               };

               //DMGHero
               if(settingRatio.baseDmgFirstTerm){
                    gamePlayRatio["DMGHero FirstTerm"] = {
                         'INTRO' : Number(settingRatio.baseDmgFirstTerm.baseDmgFirstIntro),
                         'TUNNEL_1' : Number(settingRatio.baseDmgFirstTerm.baseDmgFirstTunnel1),
                         'TUNNEL_2' : Number(settingRatio.baseDmgFirstTerm.baseDmgFirstTunnel2),
                         'TUNNEL_3' : Number(settingRatio.baseDmgFirstTerm.baseDmgFirstTunnel3),
                         'DEFAULT' : Number(settingRatio.baseDmgFirstTerm.baseDmgFirstDefault),
                    };
               } else {
                    gamePlayRatio["DMGHero FirstTerm"] = {
                         'INTRO' : 1,
                         'TUNNEL_1' : 1,
                         'TUNNEL_2' : 1,
                         'TUNNEL_3' : 1,
                         'DEFAULT' : 1,
                    };
               }
               gamePlayRatio["DMGHero Common"] = {
                    'INTRO' : Number.parseFloat(settingRatio.baseDmgRatio.baseDmgIntro),
                    'TUNNEL_1' : Number.parseFloat(settingRatio.baseDmgRatio.baseDmgTunnel1),
                    'TUNNEL_2' : Number.parseFloat(settingRatio.baseDmgRatio.baseDmgTunnel2),
                    'TUNNEL_3' : Number.parseFloat(settingRatio.baseDmgRatio.baseDmgTunnel3),
                    'DEFAULT' : Number.parseFloat(settingRatio.baseDmgRatio.baseDmgDefault),
               };

               //CostHero
               if(settingRatio.costHeroLevelUpFirstTerm){
                    gamePlayRatio["CostHero FirstTerm"] = {
                         'INTRO' : Number.parseFloat(settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstDefault),
                    };
               } else {
                    gamePlayRatio["CostHero FirstTerm"] = {
                         'INTRO' : Number(settingRatio.seriesFirstTermCostHero),
                         'TUNNEL_1' : Number(settingRatio.seriesFirstTermCostHero),
                         'TUNNEL_2' : Number(settingRatio.seriesFirstTermCostHero),
                         'TUNNEL_3' : Number(settingRatio.seriesFirstTermCostHero),
                         'DEFAULT' : Number(settingRatio.seriesFirstTermCostHero),
                    };
               }
               gamePlayRatio["CostHero Common"] = {
                    'INTRO' : Number.parseFloat(settingRatio.costHeroLevelUpRatio.costHeroLevelUpIntro),
                    'TUNNEL_1' : Number.parseFloat(settingRatio.costHeroLevelUpRatio.costHeroLevelUpTunnel1),
                    'TUNNEL_2' : Number.parseFloat(settingRatio.costHeroLevelUpRatio.costHeroLevelUpTunnel2),
                    'TUNNEL_3' : Number.parseFloat(settingRatio.costHeroLevelUpRatio.costHeroLevelUpTunnel3),
                    'DEFAULT' : Number.parseFloat(settingRatio.costHeroLevelUpRatio.costHeroLevelUpDefault),
               };

               //HPGhost
               if(settingRatio.baseHPGhostFirstTerm){
                    gamePlayRatio["HPGhost FirstTerm"] = {
                         'INTRO' : Number(settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstIntro),
                         'TUNNEL_1' : Number(settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstTunnel1),
                         'TUNNEL_2' : Number(settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstTunnel2),
                         'TUNNEL_3' : Number(settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstTunnel3),
                         'DEFAULT' : Number(settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstDefault),
                    };
               } else {
                    gamePlayRatio["HPGhost FirstTerm"] = {
                         'INTRO' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_1' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_2' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_3' : Number(settingRatio.seriesFirstTermGhost),
                         'DEFAULT' : Number(settingRatio.seriesFirstTermGhost),
                    };
               }
               gamePlayRatio["HPGhost Common"] = {
                    'INTRO' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostIntro),
                    'TUNNEL_1' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel1),
                    'TUNNEL_2' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel2),
                    'TUNNEL_3' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel3),
                    'DEFAULT' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostDefault),
               };

               //HPBoss
               if(settingRatio.baseHPNormalBossFirstTerm){
                    gamePlayRatio["HPBoss FirstTerm"] = {
                         'INTRO' : Number(settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstIntro),
                         'TUNNEL_1' : Number(settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel1),
                         'TUNNEL_2' : Number(settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel2),
                         'TUNNEL_3' : Number(settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel3),
                         'DEFAULT' : Number(settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstDefault),
                    };
               } else {
                    gamePlayRatio["HPBoss FirstTerm"] = {
                         'INTRO' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_1' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_2' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_3' : Number(settingRatio.seriesFirstTermGhost),
                         'DEFAULT' : Number(settingRatio.seriesFirstTermGhost),
                    };
               }
               if(settingRatio.baseHPNormalBossRatio){
                    gamePlayRatio["HPBoss Common"] = {
                         'INTRO' : Number.parseFloat(settingRatio.baseHPNormalBossRatio.baseHPNormalBossIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.baseHPNormalBossRatio.baseHPNormalBossTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.baseHPNormalBossRatio.baseHPNormalBossTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.baseHPNormalBossRatio.baseHPNormalBossTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.baseHPNormalBossRatio.baseHPNormalBossDefault),
                    };
               } else {
                    //take from ghost
                    gamePlayRatio["HPBoss Common"] = {
                         'INTRO' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostDefault),
                    };
               }

               //HPZoneBoss
               if(settingRatio.baseHPZoneBossFirstTerm){
                    gamePlayRatio["HPZoneBoss FirstTerm"] = {
                         'INTRO' : Number(settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstIntro),
                         'TUNNEL_1' : Number(settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel1),
                         'TUNNEL_2' : Number(settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel2),
                         'TUNNEL_3' : Number(settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel3),
                         'DEFAULT' : Number(settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstDefault),
                    };
               } else {
                    gamePlayRatio["HPZoneBoss FirstTerm"] = {
                         'INTRO' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_1' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_2' : Number(settingRatio.seriesFirstTermGhost),
                         'TUNNEL_3' : Number(settingRatio.seriesFirstTermGhost),
                         'DEFAULT' : Number(settingRatio.seriesFirstTermGhost),
                    };
               }
               if(settingRatio.baseHPZoneBossRatio){
                    gamePlayRatio["HPZoneBoss Common"] = {
                         'INTRO' : Number.parseFloat(settingRatio.baseHPZoneBossRatio.baseHPZoneBossIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.baseHPZoneBossRatio.baseHPZoneBossTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.baseHPZoneBossRatio.baseHPZoneBossTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.baseHPZoneBossRatio.baseHPZoneBossTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.baseHPZoneBossRatio.baseHPZoneBossDefault),
                    };
               } else {
                    //take from ghost
                    gamePlayRatio["HPZoneBoss Common"] = {
                         'INTRO' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.baseHPGhostRatio.baseHPGhostDefault),
                    };
               }

               //DMGBoss
               if(settingRatio.dmgBossFirstTerm){
                    //DMGBoss
                    gamePlayRatio["DMGBoss FirstTerm"] = {
                         'INTRO' : Number.parseFloat(settingRatio.dmgBossFirstTerm.dmgBossFirstIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.dmgBossFirstTerm.dmgBossFirstTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.dmgBossFirstTerm.dmgBossFirstTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.dmgBossFirstTerm.dmgBossFirstTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.dmgBossFirstTerm.dmgBossFirstDefault),
                    };
                    gamePlayRatio["DMGBoss Common"] = {
                         'INTRO' : Number.parseFloat(settingRatio.dmgBossCommonRatio.dmgBossCommonIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.dmgBossCommonRatio.dmgBossCommonTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.dmgBossCommonRatio.dmgBossCommonTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.dmgBossCommonRatio.dmgBossCommonTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.dmgBossCommonRatio.dmgBossCommonDefault),
                    };
               } else{
                    /*don't see so use test value*/
                    gamePlayRatio["DMGBoss FirstTerm"] = {
                         'INTRO' : 1,
                         'TUNNEL_1' : 1,
                         'TUNNEL_2' : 1,
                         'TUNNEL_3' : 1,
                         'DEFAULT' : 1,
                    };
                    gamePlayRatio["DMGBoss Common"] = {
                         'INTRO' : 1.075,
                         'TUNNEL_1' : 1.095,
                         'TUNNEL_2' : 1.1,
                         'TUNNEL_3' : 1.2,
                         'DEFAULT' : 1.3,
                    };
               }

               //DMGSupport
               if(settingRatio.supportDmgFirstTerm){
                    gamePlayRatio["DMGSupport FirstTerm"] = {
                         'INTRO' : Number.parseFloat(settingRatio.supportDmgFirstTerm.supportBaseDmgFirstIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.supportDmgFirstTerm.supportBaseDmgFirstTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.supportDmgFirstTerm.supportBaseDmgFirstTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.supportDmgFirstTerm.supportBaseDmgFirstTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.supportDmgFirstTerm.supportBaseDmgFirstDefault),
                    };
               } else {
                    gamePlayRatio["DMGSupport FirstTerm"] = {
                         'INTRO' : 1,
                         'TUNNEL_1' : 2,
                         'TUNNEL_2' : 3,
                         'TUNNEL_3' : 4,
                         'DEFAULT' : 5,
                    };
               }
               gamePlayRatio["DMGSupport Common"] = {
                    'INTRO' : Number.parseFloat(settingRatio.supportDmgRatio.supportBaseDmgIntro),
                    'TUNNEL_1' : Number.parseFloat(settingRatio.supportDmgRatio.supportBaseDmgTunnel1),
                    'TUNNEL_2' : Number.parseFloat(settingRatio.supportDmgRatio.supportBaseDmgTunnel2),
                    'TUNNEL_3' : Number.parseFloat(settingRatio.supportDmgRatio.supportBaseDmgTunnel3),
                    'DEFAULT' : Number.parseFloat(settingRatio.supportDmgRatio.supportBaseDmgDefault),
               };

               //CostSupport
               if(settingRatio.costSupportLevelUpFirstTerm){
                    gamePlayRatio["CostSupport FirstTerm"] = {
                         'INTRO' : Number.parseFloat(settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstIntro),
                         'TUNNEL_1' : Number.parseFloat(settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel1),
                         'TUNNEL_2' : Number.parseFloat(settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel2),
                         'TUNNEL_3' : Number.parseFloat(settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel3),
                         'DEFAULT' : Number.parseFloat(settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstDefault),
                    };
               } else {
                    gamePlayRatio["CostSupport FirstTerm"] = {
                         'INTRO' : 5,
                         'TUNNEL_1' : 10,
                         'TUNNEL_2' : 30,
                         'TUNNEL_3' : 40,
                         'DEFAULT' : 50,
                    };
               }
               gamePlayRatio["CostSupport Common"] = {
                    'INTRO' : Number.parseFloat(settingRatio.costSupportLevelUpRatio.costSupportLevelUpIntro),
                    'TUNNEL_1' : Number.parseFloat(settingRatio.costSupportLevelUpRatio.costSupportLevelUpTunnel1),
                    'TUNNEL_2' : Number.parseFloat(settingRatio.costSupportLevelUpRatio.costSupportLevelUpTunnel2),
                    'TUNNEL_3' : Number.parseFloat(settingRatio.costSupportLevelUpRatio.costSupportLevelUpTunnel3),
                    'DEFAULT' : Number.parseFloat(settingRatio.costSupportLevelUpRatio.costSupportLevelUpDefault),
               };
               //console.log(gamePlayRatio["CostSupport FirstTerm"]);

               //adding for gold formula
               gamePlayRatio.ghostCounterPerLv = settingRatio.ghostCounterPerLv ? Number(settingRatio.ghostCounterPerLv) : 9; //=1 level, appear 9 ghost
               gamePlayRatio.bossCounterPerLv = settingRatio.bossCounterPerLv ? Number(settingRatio.bossCounterPerLv) : 0.9; //=10 levels, appear 9 boss
               gamePlayRatio.zoneBossCounterPerLv = settingRatio.zoneBossCounterPerLv ? Number(settingRatio.zoneBossCounterPerLv) : 0.1; //=10 levels, appear 1 zone bozz
               gamePlayRatio.bossGoldGhostRatio = settingRatio.bossGoldGhostRatio ? Number(settingRatio.bossGoldGhostRatio) : 2; //=2 time of ghost gold
               gamePlayRatio.zoneBossGoldGhostRatio = settingRatio.zoneBossGoldGhostRatio ? Number(settingRatio.zoneBossGoldGhostRatio) : 4; //=4 time of ghost gold

               gamePlayRatio.diamondGameStartup = settingRatio.diamondGameStartup ? Number(settingRatio.diamondGameStartup) : 90;
               gamePlayRatio.stageCounterToDropDiamond = settingRatio.stageCounterToDropDiamond ? Number(settingRatio.stageCounterToDropDiamond) : 10;

               gamePlayRatio.normalBossHPMultiplier = settingRatio.normalBossHPMultiplier ? Number(settingRatio.normalBossHPMultiplier): 1.1;
               gamePlayRatio.zoneBossHPMultiplier = settingRatio.zoneBossHPMultiplier ? Number(settingRatio.zoneBossHPMultiplier) : 1.2;

               gamePlayRatio.selectedHpBossOption = settingRatio.selectedHpBossOption ? settingRatio.selectedHpBossOption : "Ratio";
               
               gamePlayRatio.seriesFirstTermGhost = settingRatio.seriesFirstTermGhost ? Number(settingRatio.seriesFirstTermGhost) : 20;
               gamePlayRatio.seriesFirstTermCostHero = settingRatio.seriesFirstTermCostHero ? Number(settingRatio.seriesFirstTermCostHero) : 1;
               gamePlayRatio.seriesFirstTermDropCoins = settingRatio.seriesFirstTermDropCoins ? Number(settingRatio.seriesFirstTermDropCoins) : 1;
               gamePlayRatio.hitPerSeconds = settingRatio.hitPerSeconds ? Number(settingRatio.hitPerSeconds) : 5;
               gamePlayRatio.hitBossPerSeconds = settingRatio.hitBossPerSeconds ? Number(settingRatio.hitBossPerSeconds) : 15;

               gamePlayRatio.percentSupportGoldDropSkillControl = settingRatio.percentSupportGoldDropSkillControl ? Number(settingRatio.percentSupportGoldDropSkillControl) : 100;
               gamePlayRatio.percentPetGoldDropSkillControl = settingRatio.percentPetGoldDropSkillControl ? Number(settingRatio.percentPetGoldDropSkillControl) : 100;
               gamePlayRatio.percentOfflineGoldControl = settingRatio.percentOfflineGoldControl ? Number(settingRatio.percentOfflineGoldControl) : 10;
               gamePlayRatio.maxGhostFarmingOfflineGold = settingRatio.maxGhostFarmingOfflineGold ? Number(settingRatio.maxGhostFarmingOfflineGold) : 5;
               gamePlayRatio.levelStageRangeForNextGoldBoostAppear = settingRatio.levelStageRangeForNextGoldBoostAppear ? Number(settingRatio.levelStageRangeForNextGoldBoostAppear) : 10;
               gamePlayRatio.ttkbNecessaryToGetGoldBoost = settingRatio.ttkbNecessaryToGetGoldBoost ? Number(settingRatio.ttkbNecessaryToGetGoldBoost) : 20;
               gamePlayRatio.hitGhostOfflineGoldPerSecond = settingRatio.hitGhostOfflineGoldPerSecond ? Number(settingRatio.hitGhostOfflineGoldPerSecond) : 2;

               gamePlayRatio.dailyGameLevelPerDay = settingRatio.dailyGameLevelPerDay ? Number(settingRatio.dailyGameLevelPerDay) : 30;
          }

          return gamePlayRatio;
     },

     getGamePlayRatioWithAllConfig(settingRatio, supportUnlockSetting, supportSkillSetting) {
          let gamePlayRatio = this.getGamePlayRatio(settingRatio);

          let listSupportData = [];
          if(supportUnlockSetting) {
               supportUnlockSetting.forEach(support => {
                    listSupportData.push({
                         m_iID: support.m_iID,
                         kpiGameLevelShouldUnlock: support.kpiGameLevelShouldUnlock,
                         m_SupportsAbilityList: [],
                    });
               });
          }
          if(supportSkillSetting){
               supportSkillSetting.forEach(supportSkill => {
                    let supportData = listSupportData.find(s => s.m_iID === supportSkill.support_id);
                    if(supportData){
                         supportData.m_SupportsAbilityList.push({
                              m_iID: supportSkill.skill_id,
                              m_skillPercent: supportSkill.m_skillPercent,
                              m_iCurrentLevel: supportSkill.m_iCurrentLevel,
                         });
                    } else {
                         console.log("Missing support data for skill mapping SID: "+supportSkill.support_id+" Skill: "+supportSkill.skill_id);
                    }
               });
          }

          gamePlayRatio["supportSetting"] = listSupportData;


          return gamePlayRatio;
     },

     setEditorRatioFromJSonGamePlay(settingRatio, supportUnlockSetting, supportSkillSetting, gamePlayRatio) {
          //DropCoins
          settingRatio.dropCoinsFirstTerm.dropCoinsGameFirstIntro = gamePlayRatio["DropCoins FirstTerm"].INTRO;
          settingRatio.dropCoinsFirstTerm.dropCoinsFirstTunnel1 = gamePlayRatio["DropCoins FirstTerm"].TUNNEL_1;
          settingRatio.dropCoinsFirstTerm.dropCoinsFirstTunnel2 = gamePlayRatio["DropCoins FirstTerm"].TUNNEL_2;
          settingRatio.dropCoinsFirstTerm.dropCoinsFirstTunnel3 = gamePlayRatio["DropCoins FirstTerm"].TUNNEL_3;
          settingRatio.dropCoinsFirstTerm.dropCoinsFirstDefault = gamePlayRatio["DropCoins FirstTerm"].DEFAULT;
          settingRatio.dropCoinsRatio.dropCoinsGameIntro = gamePlayRatio["DropCoins Common"].INTRO;
          settingRatio.dropCoinsRatio.dropCoinsTunnel1 = gamePlayRatio["DropCoins Common"].TUNNEL_1;
          settingRatio.dropCoinsRatio.dropCoinsTunnel2 = gamePlayRatio["DropCoins Common"].TUNNEL_2;
          settingRatio.dropCoinsRatio.dropCoinsTunnel3 = gamePlayRatio["DropCoins Common"].TUNNEL_3;
          settingRatio.dropCoinsRatio.dropCoinsDefault = gamePlayRatio["DropCoins Common"].DEFAULT;

          //DMGHero
          settingRatio.baseDmgFirstTerm.baseDmgFirstIntro = gamePlayRatio["DMGHero FirstTerm"].INTRO;
          settingRatio.baseDmgFirstTerm.baseDmgFirstTunnel1 = gamePlayRatio["DMGHero FirstTerm"].TUNNEL_1;
          settingRatio.baseDmgFirstTerm.baseDmgFirstTunnel2 = gamePlayRatio["DMGHero FirstTerm"].TUNNEL_2;
          settingRatio.baseDmgFirstTerm.baseDmgFirstTunnel3 = gamePlayRatio["DMGHero FirstTerm"].TUNNEL_3;
          settingRatio.baseDmgFirstTerm.baseDmgFirstDefault = gamePlayRatio["DMGHero FirstTerm"].DEFAULT;
          settingRatio.baseDmgRatio.baseDmgIntro = gamePlayRatio["DMGHero Common"].INTRO;
          settingRatio.baseDmgRatio.baseDmgTunnel1 = gamePlayRatio["DMGHero Common"].TUNNEL_1;
          settingRatio.baseDmgRatio.baseDmgTunnel2 = gamePlayRatio["DMGHero Common"].TUNNEL_2;
          settingRatio.baseDmgRatio.baseDmgTunnel3 = gamePlayRatio["DMGHero Common"].TUNNEL_3;
          settingRatio.baseDmgRatio.baseDmgDefault = gamePlayRatio["DMGHero Common"].DEFAULT;

          //CostHero
          settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstIntro = gamePlayRatio["CostHero FirstTerm"].INTRO;
          settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel1 = gamePlayRatio["CostHero FirstTerm"].TUNNEL_1;
          settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel2 = gamePlayRatio["CostHero FirstTerm"].TUNNEL_2;
          settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel3 = gamePlayRatio["CostHero FirstTerm"].TUNNEL_3;
          settingRatio.costHeroLevelUpFirstTerm.costHeroLevelUpFirstDefault = gamePlayRatio["CostHero FirstTerm"].DEFAULT;
          settingRatio.costHeroLevelUpRatio.costHeroLevelUpIntro = gamePlayRatio["CostHero Common"].INTRO;
          settingRatio.costHeroLevelUpRatio.costHeroLevelUpTunnel1 = gamePlayRatio["CostHero Common"].TUNNEL_1;
          settingRatio.costHeroLevelUpRatio.costHeroLevelUpTunnel2 = gamePlayRatio["CostHero Common"].TUNNEL_2;
          settingRatio.costHeroLevelUpRatio.costHeroLevelUpTunnel3 = gamePlayRatio["CostHero Common"].TUNNEL_3;
          settingRatio.costHeroLevelUpRatio.costHeroLevelUpDefault = gamePlayRatio["CostHero Common"].DEFAULT;

          //HPGhost
          settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstIntro = gamePlayRatio["HPGhost FirstTerm"].INTRO;
          settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstTunnel1 = gamePlayRatio["HPGhost FirstTerm"].TUNNEL_1;
          settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstTunnel2 = gamePlayRatio["HPGhost FirstTerm"].TUNNEL_2;
          settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstTunnel3 = gamePlayRatio["HPGhost FirstTerm"].TUNNEL_3;
          settingRatio.baseHPGhostFirstTerm.baseHPGhostFirstDefault = gamePlayRatio["HPGhost FirstTerm"].DEFAULT;
          settingRatio.baseHPGhostRatio.baseHPGhostIntro = gamePlayRatio["HPGhost Common"].INTRO;
          settingRatio.baseHPGhostRatio.baseHPGhostTunnel1 = gamePlayRatio["HPGhost Common"].TUNNEL_1;
          settingRatio.baseHPGhostRatio.baseHPGhostTunnel2 = gamePlayRatio["HPGhost Common"].TUNNEL_2;
          settingRatio.baseHPGhostRatio.baseHPGhostTunnel3 = gamePlayRatio["HPGhost Common"].TUNNEL_3;
          settingRatio.baseHPGhostRatio.baseHPGhostDefault = gamePlayRatio["HPGhost Common"].DEFAULT;

          //HPBoss
          settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstIntro = gamePlayRatio["HPBoss FirstTerm"].INTRO;
          settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel1 = gamePlayRatio["HPBoss FirstTerm"].TUNNEL_1;
          settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel2 = gamePlayRatio["HPBoss FirstTerm"].TUNNEL_2;
          settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel3 = gamePlayRatio["HPBoss FirstTerm"].TUNNEL_3;
          settingRatio.baseHPNormalBossFirstTerm.baseHPNormalBossFirstDefault = gamePlayRatio["HPBoss FirstTerm"].DEFAULT;
          settingRatio.baseHPNormalBossRatio.baseHPNormalBossIntro = gamePlayRatio["HPBoss Common"].INTRO;
          settingRatio.baseHPNormalBossRatio.baseHPNormalBossTunnel1 = gamePlayRatio["HPBoss Common"].TUNNEL_1;
          settingRatio.baseHPNormalBossRatio.baseHPNormalBossTunnel2 = gamePlayRatio["HPBoss Common"].TUNNEL_2;
          settingRatio.baseHPNormalBossRatio.baseHPNormalBossTunnel3 = gamePlayRatio["HPBoss Common"].TUNNEL_3;
          settingRatio.baseHPNormalBossRatio.baseHPNormalBossDefault = gamePlayRatio["HPBoss Common"].DEFAULT;

          //HPZoneBoss
          settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstIntro = gamePlayRatio["HPZoneBoss FirstTerm"].INTRO;
          settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel1 = gamePlayRatio["HPZoneBoss FirstTerm"].TUNNEL_1;
          settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel2 = gamePlayRatio["HPZoneBoss FirstTerm"].TUNNEL_2;
          settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel3 = gamePlayRatio["HPZoneBoss FirstTerm"].TUNNEL_3;
          settingRatio.baseHPZoneBossFirstTerm.baseHPZoneBossFirstDefault = gamePlayRatio["HPZoneBoss FirstTerm"].DEFAULT;
          settingRatio.baseHPZoneBossRatio.baseHPZoneBossIntro = gamePlayRatio["HPZoneBoss Common"].INTRO;
          settingRatio.baseHPZoneBossRatio.baseHPZoneBossTunnel1 = gamePlayRatio["HPZoneBoss Common"].TUNNEL_1;
          settingRatio.baseHPZoneBossRatio.baseHPZoneBossTunnel2 = gamePlayRatio["HPZoneBoss Common"].TUNNEL_2;
          settingRatio.baseHPZoneBossRatio.baseHPZoneBossTunnel3 = gamePlayRatio["HPZoneBoss Common"].TUNNEL_3;
          settingRatio.baseHPZoneBossRatio.baseHPZoneBossDefault = gamePlayRatio["HPZoneBoss Common"].DEFAULT;

          //DMGBoss
          settingRatio.dmgBossFirstTerm.dmgBossFirstIntro = gamePlayRatio["DMGBoss FirstTerm"].INTRO;
          settingRatio.dmgBossFirstTerm.dmgBossFirstTunnel1 = gamePlayRatio["DMGBoss FirstTerm"].TUNNEL_1;
          settingRatio.dmgBossFirstTerm.dmgBossFirstTunnel2 = gamePlayRatio["DMGBoss FirstTerm"].TUNNEL_2;
          settingRatio.dmgBossFirstTerm.dmgBossFirstTunnel3 = gamePlayRatio["DMGBoss FirstTerm"].TUNNEL_3;
          settingRatio.dmgBossFirstTerm.dmgBossFirstDefault = gamePlayRatio["DMGBoss FirstTerm"].DEFAULT;
          settingRatio.dmgBossCommonRatio.dmgBossCommonIntro = gamePlayRatio["DMGBoss Common"].INTRO;
          settingRatio.dmgBossCommonRatio.dmgBossCommonTunnel1 = gamePlayRatio["DMGBoss Common"].TUNNEL_1;
          settingRatio.dmgBossCommonRatio.dmgBossCommonTunnel2 = gamePlayRatio["DMGBoss Common"].TUNNEL_2;
          settingRatio.dmgBossCommonRatio.dmgBossCommonTunnel3 = gamePlayRatio["DMGBoss Common"].TUNNEL_3;
          settingRatio.dmgBossCommonRatio.dmgBossCommonDefault = gamePlayRatio["DMGBoss Common"].DEFAULT;

          //DMGSupport
          settingRatio.supportDmgFirstTerm.supportBaseDmgFirstIntro = gamePlayRatio["DMGSupport FirstTerm"].INTRO;
          settingRatio.supportDmgFirstTerm.supportBaseDmgFirstTunnel1 = gamePlayRatio["DMGSupport FirstTerm"].TUNNEL_1;
          settingRatio.supportDmgFirstTerm.supportBaseDmgFirstTunnel2 = gamePlayRatio["DMGSupport FirstTerm"].TUNNEL_2;
          settingRatio.supportDmgFirstTerm.supportBaseDmgFirstTunnel3 = gamePlayRatio["DMGSupport FirstTerm"].TUNNEL_3;
          settingRatio.supportDmgFirstTerm.supportBaseDmgFirstDefault = gamePlayRatio["DMGSupport FirstTerm"].DEFAULT;
          settingRatio.supportDmgRatio.supportBaseDmgIntro = gamePlayRatio["DMGSupport Common"].INTRO;
          settingRatio.supportDmgRatio.supportBaseDmgTunnel1 = gamePlayRatio["DMGSupport Common"].TUNNEL_1;
          settingRatio.supportDmgRatio.supportBaseDmgTunnel2 = gamePlayRatio["DMGSupport Common"].TUNNEL_2;
          settingRatio.supportDmgRatio.supportBaseDmgTunnel3 = gamePlayRatio["DMGSupport Common"].TUNNEL_3;
          settingRatio.supportDmgRatio.supportBaseDmgDefault = gamePlayRatio["DMGSupport Common"].DEFAULT;


          //CostSupport
          settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstIntro = gamePlayRatio["CostSupport FirstTerm"].INTRO;
          settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel1 = gamePlayRatio["CostSupport FirstTerm"].TUNNEL_1;
          settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel2 = gamePlayRatio["CostSupport FirstTerm"].TUNNEL_2;
          settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel3 = gamePlayRatio["CostSupport FirstTerm"].TUNNEL_3;
          settingRatio.costSupportLevelUpFirstTerm.costSupportLevelUpFirstDefault = gamePlayRatio["CostSupport FirstTerm"].DEFAULT;
          settingRatio.costSupportLevelUpRatio.costSupportLevelUpIntro = gamePlayRatio["CostSupport Common"].INTRO;
          settingRatio.costSupportLevelUpRatio.costSupportLevelUpTunnel1 = gamePlayRatio["CostSupport Common"].TUNNEL_1;
          settingRatio.costSupportLevelUpRatio.costSupportLevelUpTunnel2 = gamePlayRatio["CostSupport Common"].TUNNEL_2;
          settingRatio.costSupportLevelUpRatio.costSupportLevelUpTunnel3 = gamePlayRatio["CostSupport Common"].TUNNEL_3;
          settingRatio.costSupportLevelUpRatio.costSupportLevelUpDefault = gamePlayRatio["CostSupport Common"].DEFAULT;


          //adding for gold formula
          settingRatio.ghostCounterPerLv = gamePlayRatio.ghostCounterPerLv;
          settingRatio.bossCounterPerLv = gamePlayRatio.bossCounterPerLv;
          settingRatio.zoneBossCounterPerLv = gamePlayRatio.zoneBossCounterPerLv; //=10 levels, appear 1 zone bozz
          settingRatio.bossGoldGhostRatio = gamePlayRatio.bossGoldGhostRatio; //=2 time of ghost gold
          settingRatio.zoneBossGoldGhostRatio = gamePlayRatio.zoneBossGoldGhostRatio; //=4 time of ghost gold

          settingRatio.diamondGameStartup = gamePlayRatio.diamondGameStartup;
          settingRatio.stageCounterToDropDiamond = gamePlayRatio.stageCounterToDropDiamond;

          settingRatio.normalBossHPMultiplier = gamePlayRatio.normalBossHPMultiplier;
          settingRatio.zoneBossHPMultiplier = gamePlayRatio.zoneBossHPMultiplier;

          settingRatio.selectedHpBossOption = gamePlayRatio.selectedHpBossOption;

          settingRatio.seriesFirstTermGhost = gamePlayRatio.seriesFirstTermGhost;
          settingRatio.seriesFirstTermCostHero = gamePlayRatio.seriesFirstTermCostHero;
          settingRatio.seriesFirstTermDropCoins = gamePlayRatio.seriesFirstTermDropCoins;
          settingRatio.hitPerSeconds = gamePlayRatio.hitPerSeconds;
          settingRatio.hitBossPerSeconds = gamePlayRatio.hitBossPerSeconds;

          settingRatio.percentSupportGoldDropSkillControl = gamePlayRatio.percentSupportGoldDropSkillControl;
          settingRatio.percentPetGoldDropSkillControl = gamePlayRatio.percentPetGoldDropSkillControl;
          settingRatio.percentOfflineGoldControl = gamePlayRatio.percentOfflineGoldControl;
          settingRatio.maxGhostFarmingOfflineGold = gamePlayRatio.maxGhostFarmingOfflineGold;
          settingRatio.levelStageRangeForNextGoldBoostAppear = gamePlayRatio.levelStageRangeForNextGoldBoostAppear;
          settingRatio.ttkbNecessaryToGetGoldBoost = gamePlayRatio.ttkbNecessaryToGetGoldBoost;
          settingRatio.hitGhostOfflineGoldPerSecond = gamePlayRatio.hitGhostOfflineGoldPerSecond;

          settingRatio.dailyGameLevelPerDay = gamePlayRatio.dailyGameLevelPerDay;

          //supportUnlockSetting
          let listSupportData = gamePlayRatio.supportSetting;
          supportUnlockSetting.forEach(support => {
               let supportGamePlay = listSupportData.find(s => s.m_iID === support.m_iID);
               support.kpiGameLevelShouldUnlock = supportGamePlay.kpiGameLevelShouldUnlock;
          });

          //supportSkillSetting
          supportSkillSetting.forEach(supportSkill => {
               let supportData = listSupportData.find(s => s.m_iID === supportSkill.support_id);
               if(supportData){
                    let skGamePlay = supportData.m_SupportsAbilityList.find(s => s.m_iID === supportSkill.skill_id);
                    supportSkill.m_skillPercent = skGamePlay.m_skillPercent;
                    supportSkill.m_iCurrentLevel = skGamePlay.m_iCurrentLevel;
               } else {
                    console.log("Missing support data for skill mapping SID: "+supportSkill.support_id+" Skill: "+supportSkill.skill_id);
               }
          });
     },
}