<template>
    <v-row justify="center">
        <v-btn
                color="primary"
                dark
                @click.stop="open()"
        >
            EXPORT TO GAME
        </v-btn>

        <v-dialog
                v-model="dialog"
                max-width="800px"
                @click:outside="onExit"

        >

            <v-card>
                <v-card-title class="headline">Data Source Value</v-card-title>

                <v-footer
                        color="primary lighten-1"
                        padless
                >
                    <v-row
                            justify="center"
                            no-gutters
                    >
                        <v-btn
                                @click="showLanguage('ENG')"
                                color="white"
                                text
                                rounded
                                class="my-2"
                        >
                            Language File (EN)
                        </v-btn>

                        <v-btn
                                @click="showLanguage('FR')"
                                color="white"
                                text
                                rounded
                                class="my-2"
                        >
                            Language File (FR)
                        </v-btn>

                        <v-btn
                                @click="showLanguage('KH')"
                                color="white"
                                text
                                rounded
                                class="my-2"
                        >
                            Language File (KH)
                        </v-btn>

                        <v-btn
                                color="white"
                                text
                                rounded
                                class="my-2"
                                @click="showSupport()"
                        >
                            Support File
                        </v-btn>

                        <v-btn
                                color="white"
                                text
                                rounded
                                class="my-2"
                                @click="showIconToDelete()"
                        >
                            Icon to delete
                        </v-btn>
                        <v-btn
                                color="white"
                                text
                                rounded
                                class="my-2"
                                @click="showIconUsed()"
                        >
                            Icon used
                        </v-btn>
                    </v-row>
                </v-footer>

            <v-card min-height="400" max-height="400" class="overflow-y-auto">

                <v-card-text>

                </v-card-text>


                                <pre >
                                    {{log}}
                                </pre>


            </v-card>




            <v-card-actions class="justify-center">


                            <v-btn @click="copyText">{{getCopyTitle()}}</v-btn>


            </v-card-actions>

            </v-card>

        </v-dialog>
    </v-row>
</template>

<script>

    import exporter from '../CodeExporter.js'


    export default {
        data () {
            return {
                dialog: false,
                copy: false,
                log : "",
                contentType:'json',
            }
        },
        methods: {
            open: function() {
                this.dialog = true;
                //let result = exporter.exportGameLanguageData('ENG', this.propDataSource);
                let result = exporter.exportGameSkillStructures(this.propDataSource, this.unlockSupportSetting);
                //console.log('HEY OPEN WINDOW');
                ////console.log(result);
                this.log = result;

                // let test =  exporter.usedByNewSkill(this.oldIconList,this.propDataSource);
                // console.log(test);

            },
            copyText () {
                this.copy = true;
                if (this.contentType === 'json') {
                    navigator.clipboard.writeText(JSON.stringify(this.log),null,'t');
                }else {
                    navigator.clipboard.writeText(this.log);
                }

            },

            onExit () {
                this.copy = false;
            },
            getCopyTitle () {
                if (this.copy) {
                    return 'copied';
                }else {
                    return 'copy';
                }
            },
            showLanguage(lang) {
                this.copy = false;
                this.log = exporter.exportGameLanguageData(lang, this.propDataSource);
                this.contentType = 'code'
            },
            showSupport() {
                this.copy = false;
                this.log = exporter.exportGameSkillStructures( this.propDataSource, this.unlockSupportSetting);
                this.contentType = 'json'
            },
            showIconToDelete() {
                this.copy = false;
                this.log = exporter.usedByNewSkill(this.oldIconList,this.propDataSource);
                this.contentType = 'json'
            },
            showIconUsed() {
                this.copy = false;
                this.log = exporter.exportListOfIconsUsed(this.propDataSource);
                this.contentType = 'json'
            }
        },
        props:['propDataSource','oldIconList','unlockSupportSetting']
    }
</script>
