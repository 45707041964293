
export default {
    data () {
        return {

            search: '',

            headers: [
                {
                    text: 'Support ',
                    align: 'start',
                    sortable: true,
                    value: 'support'
                },



                {
                    text: 'Skill ',
                    align: 'start',
                    sortable: true,
                    value: 'skillType'
                },

                {
                    text: 'Skill Name',
                    align: 'start',
                    sortable: false,
                    value: 'm_sName'
                },

                {
                    text: 'Skill New Name',
                    align: 'start',
                    sortable: false,
                    groupable:false,
                    value: 'm_sName2'
                },



                { text: 'Color', value: 'supportData.color'  },

                { text: 'CurrentIcon', value: 'm_iIconId' },
                //{ text: 'Fat (g)', value: 'fat' },
                { text: 'Icons', value: 'icon' },

                { text: 'Completion', value: 'completed' },


            ],
            //list: supportLoad.list
        }
    },
}
