
import supports from './SupportData.js';
import axios from 'axios'
import NetworkUtils from "@/NetworkUtils";
import store from "./store";

export default {
    loadOldIcons : async function() {
        let oldIconList = [];
        supports.forEach(support => {
            support.m_SupportsAbilityList.forEach(skill => {
                if (! oldIconList.includes(skill.m_iIconId)) {
                    oldIconList.push(skill.m_iIconId);
                }
            })
        });
        return oldIconList;
    },


    loadSkillData : async function() {
        let skillList = [];
        let authorize =  `Bearer ${store.getters.getToken}`;
        let config = {
            headers: { Authorization: authorize }
        };

        let data = await axios.get(NetworkUtils.HOST + "/skills?_limit=1&_sort=id:DESC", config);
        if (data.data.length > 0) {
            skillList = data.data[0].list;
        }else {
            skillList = require('../legacy/strapiSkills.json');
        }
        return skillList;
    }

}
