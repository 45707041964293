//store/modules/auth.js

import axios from 'axios';
import NetworkUtils from "@/NetworkUtils";
import LocalStorageUtils from "@/kpi-editor/Utils/LocalStorageUtils";
const state = {
    token: null,
};
const getters = {
    isAuthenticated: () => {
        let token = LocalStorageUtils.getObject("sompom123key2023_token");
        return !!token;
    },
    Token: () => {
        let token = LocalStorageUtils.getObject("sompom123key2023_token");
        return token;
    },
    getToken: () =>{
        if(state.token){
            return state.token;
        }
        return LocalStorageUtils.getObject("sompom123key2023_token");
    },
};
const actions = {
    async LogIn({commit}, user) {
        let url = NetworkUtils.HOST+ '/auth/local';
        let response = await axios.post(url, user);
        // console.log(response)
        await commit("setToken", response.data.jwt);
        // console.log(response.data.jwt)
    },
};
const mutations = {
    setToken(state, token) {
        state.token = token;
        LocalStorageUtils.saveObject("sompom123key2023_token", token);
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};
