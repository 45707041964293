let SMPNum = require("../../SMPNum");
let PetConstants = require('./../../constants/PetSkillConstants');


class SMPPetGamePlay {


    constructor() {

    }

    SetPetUnlockLevel(petList){
        let levelUnlock = 10;
        petList.forEach(pet => {
            pet.levelUnlock = levelUnlock;
            if(levelUnlock < 100){
                levelUnlock += 10;
            } else {
                levelUnlock += 100;
            }
        });
    }

    GetPetCountUnlockOnLevel(level) {
        if (level <= 100) {
            return Math.floor(level / 10);
        } else {
            let count = 9 + Math.floor(level / 100);
            if (count > 39) {
                count = 39;
            }
            return count;
        }
    }

    getDiamondCostUpdatePetLevel(level) {
        let diamondCostRatio = 5;
        let cost = diamondCostRatio * (level - 1);
        return cost;
    }

    getPetDMG(heroTapDMG, petLevel, petData){
        let dmgPercent = this.getDMGPercentage(petLevel, petData) / 100;
        return SMPNum.multSmpNum(heroTapDMG, SMPNum.fromNum(dmgPercent));
    }

    getPetPassiveDMG(heroTapDMG, petLevel, petData){
        let lvGroup = Math.floor(petLevel / 5) * 5;
        if (lvGroup > 0)
        {
            let dmgPercent = this.getDMGPercentage(petLevel, petData);
            let passivePercent = dmgPercent * (lvGroup / 100.0);
            return SMPNum.multSmpNum(heroTapDMG, SMPNum.fromNum(passivePercent /100));
        } else {
            return SMPNum.fromNum(0);
        }
    }

    getDMGPercentage(petLevel, petData){
        let di = 0;
        if (petLevel <= 40)
        {
            di = petLevel * petData.petDamIncPerLvT1;
        }
        else if (petLevel <= 80)
        {
            di = (40 * petData.petDamIncPerLvT1) + (petLevel - 40) * petData.petDamIncPerLvT2;
        }
        else
        {
            di = (40 * petData.petDamIncPerLvT1) + (80 - 40) * petData.petDamIncPerLvT2 + (petLevel - 80) * petData.petDamIncPerLvT3;
        }
        return petData.petBaseDamage + di;
    }

    //---------merge with Old KPI----------------
    // GetDiamondCostForLevelUpPet(totalPet, totalLevel) {
    //     let diamondCostRatio = 10;
    //     let firstLevel = 0;
    //     let sum = firstLevel + (totalLevel - 1) * diamondCostRatio;
    //     if (totalLevel === 1) {
    //         return 0;
    //     }
    //     sum = sum * totalPet;
    //     return sum;
    // }

    generateRegenerationTime(petLevel) {
        ////console.log('generateRegenerationTime');
        return 60 * (petLevel-1) + (60 * 60);
    }

    generateActiveSkillPercent(petLevel,petData) {
        if (petLevel <= 0) return 0;
        let value = petLevel * petData.petBoIncPerLv + petData.petBaseBonus;
        return value;
    }

    generatePassiveSkillPercent(petLevel,petData) {
        /*on game play*/
        let lvGroup = Math.floor(petLevel / 5) * 5;//we should you floor to get (level/5) for fix bug of pet percentage increase rule [5 level per time]
        let activePetBonus = this.generateActiveSkillPercent(petLevel, petData);
        if (lvGroup > 0)
        {
            return activePetBonus * (lvGroup / 100.0);
        } else {
            return 0;
        }
        /*in old kpi editor
        let value = petLevel * petData.petBoIncPerLv + petData.petBaseBonus;
        return value * 0.1;*/
    }

    findSkillId (skillName) {
        let result = -1;
        switch (skillName) {
            case 'ALL_DAMAGE': {
                result = PetConstants.ALL_DAMAGE;
                break;
            }
            case 'TAP_DAMAGE': {
                result = PetConstants.TAP_DAMAGE;
                break;
            }
            case 'ALL_GOLD': {
                result = PetConstants.ALL_GOLD;
                break;
            }
            case 'ALL_SUPPORTER_DAMAGE': {
                result = PetConstants.ALL_SUPPORTER_DAMAGE;
                break;
            }
        }
        return result;
    }

    computeAverageActivePetDamage(heroDamage, petAttackDamage, totalBonusPassiveDMG) {
        let maxAttackCounter = 20;

        if(petAttackDamage.isZero){
            return SMPNum.fromNum(0);
        }

        let bigDmg = this.GetPetFinalDamage(petAttackDamage, totalBonusPassiveDMG);
        let miniDmg = this.GetPetMiniDamage(petAttackDamage, totalBonusPassiveDMG);

        let avgDmg = SMPNum.divSmpNum(SMPNum.plus(SMPNum.multSmpNum(miniDmg
                                                                , SMPNum.fromNum(maxAttackCounter - 1))
                                                , bigDmg)
                                    , SMPNum.fromNum(maxAttackCounter)
                );
        return avgDmg;
    }

    GetPetFinalDamage(petAttackDamage, totalBonusPassiveDMG) {
        return SMPNum.plus(petAttackDamage, totalBonusPassiveDMG);
    }

    GetPetMiniDamage(petAttackDamage, totalBonusPassiveDMG) {
        return SMPNum.plus(SMPNum.multSmpNum(petAttackDamage , SMPNum.fromNum(0.1))
                            , SMPNum.multSmpNum(totalBonusPassiveDMG, SMPNum.fromNum(.1))
                );
    }

    /*Old kpi balance formula
    computeAverageActivePetDamage(tapCapacity,baseDamageRatio,heroDamage) {
        // AVG == (All small tap dmg+ big tap dmg) / tap capacity
        let smpNumRatio = new SMPNum(baseDamageRatio);
        let bigDamagePet = SMPNum.multSmpNum(heroDamage,smpNumRatio);
        bigDamagePet = SMPNum.divSmpNum(bigDamagePet,new SMPNum(100));

        let smallDamagePet = SMPNum.divSmpNum(bigDamagePet,new SMPNum(10));

        let smpNumTapCapacity = new SMPNum(tapCapacity);

        let totalDamageGiven = SMPNum.multSmpNum(smallDamagePet,smpNumTapCapacity);
        totalDamageGiven = SMPNum.plus(totalDamageGiven,bigDamagePet);

        let averagePetDamage = SMPNum.divSmpNum(totalDamageGiven,smpNumTapCapacity);
        return averagePetDamage;
    }*/

    generateBaseDamage (petLevel, petData) {
        //what is the formula we are going to apply for the pet.
        // i am not sure what to do for that.
        // "petBaseDamage":300,
        //     "petDamIncPerLvT1":50,
        //     "petDamIncPerLvT2":100,
        //     "petDamIncPerLvT3":200,

        //100% === x2
        let petBaseDamage = 1/10;

        let multiplier = 1;
        if (petLevel < 33) {
            multiplier = petData.petDamIncPerLvT1;
        }
        else if (petLevel < 66) {
            multiplier = petData.petDamIncPerLvT2;
        }else {
            multiplier = petData.petDamIncPerLvT3;
        }

        petBaseDamage = petBaseDamage * (multiplier) * petLevel;
        return petBaseDamage;
    }


    generateTapCapacity(petLevel) {
        //ce qu'on veut c'est que la tapCapcity d'un pet évolue en fonction de son niveau
        // avec un minimum de 60
        // et avec un maximum de 120

        let minTap = 60;
        let maxTap = 120;
        let maxLevel = 99;

        if (petLevel === 1) {
            return minTap;
        }else{
            return Math.min(maxTap,Math.floor((petLevel/maxLevel) * maxTap ) + minTap);
        }

    }

    /*game play
    GetAttackDMG(petData, heroTapDMG) {
        return heroTapDMG * petData.petTotalDamagePercent / 100;
    }

    GetPassiveDMG(petData, heroTapDMG){
        let level = petData.petCurrentLevel;
        let lvGroup = (level / 5) * 5;
        let petAttackPassive = SMPNum.fromNum(0);
        if (lvGroup > 0) {
            petAttackPassive.setFromSMPNum(heroTapDMG * (petData.petTotalPassiveDamagePercent / 100.0));
        }

        return petAttackPassive;
    }*/
}

module.exports = SMPPetGamePlay;