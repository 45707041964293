let {ElementType} = require("../constants/ElementTypeAndName");
let SMPNum = require ("@/SMPNum");
let PET_MAX_LEVEL = 99;

class MultipleSessionKpiUtils {
    constructor(multipleSessionConfig, gamePlayDataService){
        this.config = multipleSessionConfig;
        this.gamePlayDataService = gamePlayDataService;
    }

    //DMG Session Hero and Boss
    getBaseHeroDMGWithMultipleSession(heroDmg, heroDmgSessionGainBoss, heroDmgSessionFromPet){
        let rsDmg = new SMPNum(0);
        if(heroDmgSessionFromPet){
            rsDmg = SMPNum.plus(heroDmg, heroDmgSessionFromPet.bonusDmg);
        }
        if(heroDmgSessionGainBoss){
            if(heroDmgSessionGainBoss.isGain){
                rsDmg = SMPNum.plus(heroDmg, heroDmgSessionGainBoss.bonusDmg);
            } else if(SMPNum.greaterThan(heroDmg, heroDmgSessionGainBoss.bonusDmg)){
                rsDmg = SMPNum.minus(heroDmg, heroDmgSessionGainBoss.bonusDmg);
            }
        }
        return rsDmg;
    }

    getHeroDMGSessionResultFromGainBoss(heroDmg, heroElement, bossElement) {
        let result = {};

        // apply wood[0] gain
        //-> wood[0] 0%
        //-> fire[1] -30%  => +1 = -30%
        //-> earth[2] +100% => +2 = +100%
        //-> medal[3] -100% => +3 = -100%
        //-> water[4] +30%  => +4 = +30%

        let gainNumber = this.getForwardGainNumber(heroElement, bossElement);
        let percent = this.getPercentByGainNumber(gainNumber);

        result.bonusPercent = percent;
        let isGain = percent >= 0;

        let bonusDmg = new SMPNum(0);
        let percentAbs = Math.abs(percent);
        if (percentAbs > 0) {
            //bonusDmg = heroDmg * (percentAbs / 100.0);
            bonusDmg = SMPNum.multSmpNum(heroDmg, SMPNum.fromNum(percentAbs/100.0));
        }

        /*it's not final dmg, just example formula of use gain or ungain
        var finalDmg = new SMPNum(0);
        if (isGain)
        {
            finalDmg = heroDmg + bonusDmg;
        }
        else if (heroDmg > bonusDmg)
        {
            finalDmg = heroDmg - bonusDmg;
        }*/

        result.isGain = isGain;
        result.bonusDmg = bonusDmg;
        //result.finalDmg = finalDmg;

        return result;
    }

    getForwardGainNumber(start, end) {
        if (start === end){
            return 0;
        }
        else{
            return 1 + this.getForwardGainNumber(this.GetNextStepElement(start), end);
        }
    }

    GetNextStepElement(type){
        // |> wood -> fire -> earth -> metal -> water |>
        switch (type){
            case ElementType.Wood:
                return ElementType.Fire;

            case ElementType.Fire:
                return ElementType.Earth;

            case ElementType.Earth:
                return ElementType.Metal;

            case ElementType.Metal:
                return ElementType.Water;

            case ElementType.Water:
                return ElementType.Wood;
        }

        return type;
    }

    getPercentByGainNumber(gain){
        switch (gain){
            case 1:
                return -70;

            case 2:
                return 50;

            case 3:
                return -90;

            case 4:
                return 30;

            default:
                return 0;
        }
    }

    getEnemyPercentByGainNumber(gain){
        switch (gain){
            case 1:
                return -1;

            case 2:
                return 0.2;

            case 3:
                return -1;

            case 4:
                return 0.2;

            default:
                return -1;
        }
    }
    //end Hero and Boss

    //Hero and supporter
    getHeroHpSessionResultFromSupports(heroElement, supportElements, supports) {
        // wood gain HP
        // supportElements exit water -> + 10% for wood
        // supportElements exit metal -> + 10% for water
        // supportElements exit earth -> + 10% for metal
        // supportElements exit fire -> + 10% for earth
        let resultPayload = {
            bonusHp: new SMPNum(0),
            gainCounter: 0,
            elementOrder: [],//[{elementType, counter, isGain}]
        };
        return this.ComputeBonusHpFromSupports(heroElement, supportElements, supports, resultPayload);
    }

    ComputeBonusHpFromSupports(front, elements, supports, resultPayload) {
        if (elements.length === 0) {
            return resultPayload;
        } else {
            let elementGain = this.getReverseStepElement(front);
            if(elements.includes(elementGain)){
                let matchedSupports = supports.filter(sup => sup.data.elementType === elementGain);
                let sumPercentage = new SMPNum(0);
                if(matchedSupports.length > 0){
                    matchedSupports.forEach(support =>{
                        let dmg = this.gamePlayDataService.getBossDMGByType(support.possibleLevel, 'ZoneBoss');
                        if(!dmg.isZero){
                            sumPercentage = SMPNum.plus(sumPercentage, SMPNum.multSmpNum(dmg, SMPNum.fromNum(2)));
                        }
                    });
                    resultPayload.elementOrder.push({
                        elementType: elementGain,
                        counter: matchedSupports.length,
                        isGain: true
                    });
                }

                resultPayload.bonusHp = SMPNum.plus(resultPayload.bonusHp, sumPercentage);
                resultPayload.gainCounter += matchedSupports.length;
                return this.ComputeBonusHpFromSupports(elementGain, elements.filter(s => s !== elementGain), supports, resultPayload);
            } else {
                elements.forEach(element =>{
                    let order = resultPayload.elementOrder.find(ord => ord.elementType === element);
                    if(order){
                        order.counter++;
                    } else {
                        resultPayload.elementOrder.push({
                            elementType: element,
                            counter: 1,
                            isGain: false
                        });
                    }
                });

                return resultPayload;
            }
        }
    }

    GetBackwardGainNumber(front, supports){
        if (supports.length === 0){
            return 0;
        }else{
            let elementGain = this.getReverseStepElement(front);
            if(supports.includes(elementGain)){
                return 1 + this.GetBackwardGainNumber(elementGain, supports.filter(s => s !== elementGain));
            }else{
                return 0;
            }
        }
    }

    getReverseStepElement(type) {
        // <| wood <- fire <- earth <- metal <- water <|
        switch (type) {
            case ElementType.Wood:
                return ElementType.Water;

            case ElementType.Water:
                return ElementType.Metal;

            case ElementType.Metal:
                return ElementType.Earth;

            case ElementType.Earth:
                return ElementType.Fire;

            case ElementType.Fire:
                return ElementType.Wood;
        }

        return type;
    }

    //end hero and supporter

    //DMG Session Pet and Hero
    getHeroDmgSessionResultFromPets(heroDmg, heroElement, petElements, activePets) {
        // wood gain DMG
        // petElements exit water -> + (10%+getPetDMGPercentageByLevel) for wood
        // petElements exit metal -> + (10%+getPetDMGPercentageByLevel) for water
        // petElements exit earth -> + (10%+getPetDMGPercentageByLevel) for metal
        let resultPayload = {
            bonusDmg: new SMPNum(0),
            gainCounter: 0,
            bonusPercent: 0,
            elementOrder: [],//[{elementType, counter, isGain}]
        };
        return this.ComputeBonusDmgFromPets(heroDmg, heroElement, petElements, activePets, resultPayload);
    }

    ComputeBonusDmgFromPets(heroDmg, front, elements, activePets, resultPayload) {
        if (elements.length === 0) {
            return resultPayload;
        } else {
            let elementGain = this.getReverseStepElement(front);
            if(elements.includes(elementGain)){
                let matchedPets = activePets.filter(petData => petData.elementType === elementGain);
                let sumDmg = new SMPNum(0);
                let bonusPercent = 0;
                if(matchedPets.length > 0){
                    matchedPets.forEach(petData =>{
                        let percent = petData.possibleLevel * PET_MAX_LEVEL/100;
                        bonusPercent+=percent;
                        let dmg = SMPNum.multSmpNum(heroDmg, SMPNum.fromNum(percent));
                        if(!dmg.isZero){
                            sumDmg = SMPNum.plus(sumDmg, SMPNum.divSmpNum(dmg, SMPNum.fromNum(100)));
                        }
                    });
                    resultPayload.elementOrder.push({
                        elementType: elementGain,
                        counter: matchedPets.length,
                        isGain: true,
                    });
                }

                resultPayload.bonusPercent += bonusPercent;
                resultPayload.bonusDmg = SMPNum.plus(resultPayload.bonusDmg, sumDmg);
                resultPayload.gainCounter += matchedPets.length;
                return this.ComputeBonusDmgFromPets(heroDmg, elementGain, elements.filter(s => s !== elementGain), activePets, resultPayload);
            } else {
                elements.forEach(element =>{
                    let order = resultPayload.elementOrder.find(ord => ord.elementType === element);
                    if(order){
                        order.counter++;
                    } else {
                        resultPayload.elementOrder.push({
                            elementType: element,
                            counter: 1,
                            isGain: false
                        });
                    }
                });

                return resultPayload;
            }
        }
    }
    //end Hero and Pet
}

module.exports = MultipleSessionKpiUtils;