//let SMPNum = require("../../SMPNum");

class SMPEnemyGamePlay {

    constructor(mathGamePlay){
        this.mathGamePlay = mathGamePlay;
    }

    getBossHP(monsterLevel) {
        let bossHp = this.mathGamePlay.GetUnBaseOnLevel(monsterLevel, "HPBoss");
        //bossHp.round();
        return bossHp;
    }

    getZoneBossHP(monsterLevel){
        let bossHp = this.mathGamePlay.GetUnBaseOnLevel(monsterLevel, "HPZoneBoss");
        //bossHp.round();
        return bossHp;
    }

    getGhostHP(monsterLevel){
        let ghostHp = this.mathGamePlay.GetUnBaseOnLevel(monsterLevel, "HPGhost");
        //ghostHp.round();
        return ghostHp;
    }

    getBossDMG(monsterLevel){
        return this.mathGamePlay.GetUnBaseOnLevel(monsterLevel, "DMGBoss");
    }
}

module.exports = SMPEnemyGamePlay;