<template>
    <v-row justify="center">
        <!--        <v-icon small color="blue-grey darken-2" @click.stop="dialog = true">mdi-image-multiple</v-icon>-->

        <v-dialog
                v-model="modal"
                max-width="900px"
                scrollable
                persistent

        >

            <v-card>
                <v-card-title>
                    Record : {{skillData.index}} Old Skill : {{skillData.skillType}} {{skillData.m_sName}}
                </v-card-title>
                <v-divider>  </v-divider>

                <v-card-text style="height: 900px;">

                    <v-row>
                        <v-col
                                cols="12"
                                md="4"
                        >
                            <v-text-field
                                    v-model="skillNameEn"

                                    :counter="20"
                                    label="Skill Name (en)"
                                    required
                                    ref="skillNameEn"
                                    autofocus
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                        >
                            <v-text-field
                                    v-model="skillNameFr"

                                    :counter="20"
                                    label="Skill Name (fr)"
                                    ref="skillNameFr"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                        >
                            <v-text-field
                                    v-model="skillNameKh"
                                    label="Skill Name (Kh)"
                                    :counter="20"
                                    ref="skillNameKh"
                                    required
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDialog()">Close</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDialogwithSave()">Save</v-btn>

                    <v-btn v-if="skillData.index > 0" color="blue darken-1" text @click="selectPrevItem()">Prev</v-btn>
                    <v-btn v-if="skillData.index < total-1" color="blue darken-1" text @click="selectNextItem()">Next</v-btn>



                </v-card-actions>


            </v-card>


        </v-dialog>

    </v-row>

</template>

<script>

    export default {
        data () {

            return {
                dialog: this.modal,
                localModel : {
                    skillNameKh: this.skillData.skillNameKh,
                    skillNameFr: this.skillData.skillNameFr,
                    skillNameEn: this.skillData.skillNameEn,
                },
            }
        },

        computed: {

            skillNameEn: {
                get () {
                    return this.skillData.skillNameEn
                },
                set (value) {

                    this.localModel.skillNameEn = value;
                }
            },
            skillNameFr: {
                get () {
                    return this.skillData.skillNameFr
                },
                set (value) {

                    this.localModel.skillNameFr = value;

                }
            }
            ,
        skillNameKh: {
            get () {
                ////console.log(this.skillData.skillNameKh);
                return this.skillData.skillNameKh
            },
            set (value) {
                // let eventData = {};
                // eventData.skillData = this.skillData;
                // eventData.skillNameKh = value;
                // eventData.skillNameFr = this.skillNameFr;
                // eventData.skillNameEn = this.skillNameEn;
                this.localModel.skillNameKh = value;
                //this.$emit('updateSkills', eventData )
            }
        }
    },

        created : function() {
            //console.log('ON CREATED MODAL SKILL');
            ////console.log (this);
            //this.skillNameFr = this.skillData.skillNameFr;
            //this.skillNameEn = this.skillData.skillNameEn;
            //this.skillNameKh = this.skillData.skillNameKh;
        },


        updated: function() {
            //console.log('update form');

            ////console.log(this);
            // setTimeout(() => {
            //     this.$refs.skillNameEn.$el.focus();
            // },5000)
            // this.skillNameFr = this.skillData.skillNameFr;
            // this.skillNameEn = this.skillData.skillNameEn;
            // this.skillNameKh = this.skillData.skillNameKh;

        },

        props:['skillData','modal','skillIndex','total'],

        /*
        watch: {
            skillData: function(newVal, oldVal) { // watch it
                //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                 this.localModel.skillNameFr = this.skillData.skillNameFr;
                 this.localModel.skillNameEn = this.skillData.skillNameEn;
                 this.localModel.skillNameKh = this.skillData.skillNameKh;
            }
        },*/

        methods: {

            closeDialogwithSave : function() {
                //this.dialog = false;
                //let eventData = {...this};
                //this.$emit('updateSkillName',eventData);

                //console.log('close Dialog with Save');
                //console.log(this.localModel);
                let eventData = {};
                eventData.index = this.skillData.index;
                eventData.skillNameKh = this.localModel.skillNameKh;
                eventData.skillNameFr = this.localModel.skillNameFr;
                eventData.skillNameEn = this.localModel.skillNameEn;

                this.$emit('updateSkills', eventData )
            },

            closeDialog : function() {
                //console.log(this.modal);
                this.$emit('closeModal');
            },

            selectNextItem : function() {
                let eventData = {};
                eventData.index = this.skillData.index;
                eventData.skillNameKh = this.localModel.skillNameKh;
                eventData.skillNameFr = this.localModel.skillNameFr;
                eventData.skillNameEn = this.localModel.skillNameEn;
                eventData.dontClose = true;

                this.$emit('updateSkills', eventData );
                this.$emit('doSelectNextRow',this.skillData.index)
            },

            selectPrevItem : function() {
                let eventData = {};
                eventData.index = this.skillData.index;
                eventData.skillNameKh = this.localModel.skillNameKh;
                eventData.skillNameFr = this.localModel.skillNameFr;
                eventData.skillNameEn = this.localModel.skillNameEn;
                eventData.dontClose = true;

                this.$emit('updateSkills', eventData );

                this.$emit('doSelectPrevRow',this.skillData.index)
            }



        }
    }
</script>
